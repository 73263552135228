import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { DeliveryMode } from '../components/DeliveryMode'
import {
	hideDeliveryMode,
	updateDeliveryMode,
	createNewAccount
} from '../actions/login'

export const mapStateToProps = state => {
	return {
		deliveryMode: state.loginWidget.deliveryMode,
		inputFields: state.loginWidget.inputFields,
		sbaDetails: state.loginWidget.sbaDetails,
		showDeliveryMode: state.loginWidget.showDeliveryMode,
		showTextDeliveryMode: state.loginWidget.showTextDeliveryMode,
		showEmailDeliveryMode: state.loginWidget.showEmailDeliveryMode,
		maskedUserDetails: state.loginWidget.maskedUserDetails
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onHideDeliveryMode() {
			dispatch(hideDeliveryMode())
		},
		onUpdateDeliveryMode(deliveryMode) {
			dispatch(updateDeliveryMode(deliveryMode))
		},
		onSelectDeliveryMode() {
			dispatch(createNewAccount())
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(DeliveryMode)
)
