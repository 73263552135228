import React from 'react'
import { Button, Panel, RadioButton } from '@wf/ria'
import { FormattedMessage } from 'react-intl'
import { RICOH } from '../businessSegmentBrandIds'
import { BasicTable } from 'ecpfs-react-data-driven-components'
import { SystemMessage } from './SystemMessages'

export function SecureAccountFormContent({
	mode,
	existingContracts,
	newContracts,
	newlyAddedContracts,
	modeOfAddingContracts,
	formatMessage,
	generalMessage,
	outageMessage,
	onCustomTextInputValidator,
	onUpdateExistingContracts,
	onUpdateNewlyAddedContracts,
	onSetModeOfAddingContracts,
	onSetIsValidNickname
}) {
	function updateNewlyAddedContracts(newlyAddedContracts) {
		onUpdateNewlyAddedContracts(newlyAddedContracts)
	}

	function updateModeOfAddingContracts(event) {
		const modeOfAddingContracts = event.target.value
		onSetModeOfAddingContracts(modeOfAddingContracts)
		if (modeOfAddingContracts === 'automatic') {
			updateNewlyAddedContracts(newContracts)
		}
	}

	const manageContractsColumns = [
		{
			name: 'contractNumbers',
			label: formatMessage({
				id:
					'secure-account-form-column-header.existing-contracts.contract-numbers.label'
			})
		}
	]

	const addContractsColumns = [
		{
			name: 'contractNumbers',
			label: formatMessage({
				id:
					'secure-account-form-column-header.newly-added-contracts.contract-numbers.label'
			})
		}
	]

	if (mode !== RICOH) {
		manageContractsColumns.push(
			{
				name: 'zipCode',
				label: formatMessage({
					id:
						'secure-account-form-column-header.existing-contracts.zip-code.label'
				})
			},
			{
				tag: 'BasicLabelInput',
				label: formatMessage({
					id:
						'secure-account-form-column-header.newly-added-contracts.nickname.label'
				}),
				id: 'nickName',
				name: 'nickName',
				value({ rowData: { item } }) {
					return item.nickName
				},
				validations: {
					[formatMessage({
						id: 'secure-account-form.nickName-validation.message'
					})]: '^[A-Za-z0-9\\.\\,\\(\\)\\ \\*\\[.*?\\]\\-]*$'
				},
				onBlurred(event) {
					updateExistingContractNickname(
						event,
						onSetIsValidNickname,
						textInputValidator,
						updateContractNickName,
						existingContracts,
						onUpdateExistingContracts,
						onCustomTextInputValidator,
						formatMessage
					)
				}
			}
		)
	}

	if (mode !== RICOH) {
		addContractsColumns.push(
			{
				name: 'zipCode',
				label: formatMessage({
					id:
						'secure-account-form-column-header.newly-added-contracts.zip-code.label'
				})
			},
			{
				tag: 'BasicLabelInput',
				label: formatMessage({
					id:
						'secure-account-form-column-header.newly-added-contracts.nickname.label'
				}),
				name: 'nickName',
				id: 'nickName',
				value({ rowData: { item } }) {
					return item.nickName
				},
				validations: {
					[formatMessage({
						id: 'secure-account-form.nickName-validation.message'
					})]: '^[A-Za-z0-9\\.\\,\\(\\)\\ \\*\\[.*?\\]\\-]*$'
				},
				onBlurred(event) {
					updateNewlyAddedContractNickname(
						event,
						onSetIsValidNickname,
						textInputValidator,
						updateContractNickName,
						newlyAddedContracts,
						onUpdateNewlyAddedContracts,
						onCustomTextInputValidator,
						formatMessage
					)
				},
				disabled: ({ rowData: { itemKey } }) => {
					return (
						newlyAddedContracts &&
						!newlyAddedContracts.map(contract => contract.id).includes(itemKey)
					)
				}
			}
		)
	}

	function handleOnSelectRows({ selected }) {
		const newlyAddedContracts = newContracts.filter(contract =>
			selected.includes(contract.id)
		)
		updateNewlyAddedContracts(newlyAddedContracts)
	}
	return (
		<div className="SecureAccountContent">
			<p>
				<FormattedMessage id="secure-account-form.required-field.text" />
			</p>
			<div role="alert">
				<SystemMessage
					className="GeneralMessage"
					show={generalMessage.show}
					iconKind={generalMessage.iconKind}
					messageId={generalMessage.messageId}
					values={generalMessage.values}
				/>
				<SystemMessage
					className="GeneralMessage"
					show={outageMessage.show}
					iconKind={outageMessage.iconKind}
					message={outageMessage.message}
				/>
			</div>
			<h2>
				<FormattedMessage id="secure-account-form.manage-contract-numbers.header" />
			</h2>
			<BasicTable
				className="SecureAccountContent_ManageContractNumbersTable"
				expandable={{ render: toggleExpandedCell }}
				extraContent={extraContent(formatMessage)}
				columns={manageContractsColumns}
				rows={existingContracts}
				primaryKey="id"
			/>
			<h2>
				<FormattedMessage id="secure-account-form.add-contract-numbers.header" />
			</h2>
			<p>
				<FormattedMessage id="secure-account-form.add-contract-numbers.description.text" />
			</p>
			<BasicTable
				className="SecureAccountContent_AddContractNumbersTable"
				extraContent={extraContent(formatMessage)}
				columns={addContractsColumns}
				key={newlyAddedContracts.map(contract => contract.id)}
				rows={newContracts}
				primaryKey="id"
				selected={newlyAddedContracts.map(contract => contract.id)}
				onSelected={handleOnSelectRows}
			/>
			<h2>
				<FormattedMessage id="secure-account-form.add-future-contracts.header" />
			</h2>
			<p>
				<FormattedMessage id="secure-account-form.add-future-contracts.description.text" />
			</p>
			<div className="wf-row SecureAccountContent_EnrollmentOptions">
				<div>
					<FormattedMessage id="secure-account-form.contract-enrollment-options.label" />
				</div>
				<div className="wf-col--8">
					<RadioButton
						label={formatMessage({
							id: 'secure-account-form.add-contracts-automatically.label'
						})}
						value={'automatic'}
						name="automatic"
						onChange={updateModeOfAddingContracts}
						checked={modeOfAddingContracts === 'automatic'}
					/>
					<RadioButton
						label={formatMessage({
							id: 'secure-account-form.add-contracts-manually.label'
						})}
						value={'manual'}
						name="manual"
						onChange={updateModeOfAddingContracts}
						checked={modeOfAddingContracts === 'manual'}
					/>
				</div>
			</div>
		</div>
	)
}

export function updateExistingContractNickname(
	event,
	onSetIsValidNickname,
	textInputValidator,
	updateContractNickName,
	existingContracts,
	onUpdateExistingContracts,
	onCustomTextInputValidator,
	formatMessage
) {
	if (!event.value) {
		onSetIsValidNickname(true)
	} else if (
		textInputValidator(
			'nickName',
			event.value,
			onCustomTextInputValidator,
			formatMessage
		)
	) {
		onSetIsValidNickname(true)
		updateContractNickName(event, existingContracts, onUpdateExistingContracts)
	} else {
		onSetIsValidNickname(false)
	}
}

export function updateNewlyAddedContractNickname(
	event,
	onSetIsValidNickname,
	textInputValidator,
	updateContractNickName,
	newlyAddedContracts,
	onUpdateNewlyAddedContracts,
	onCustomTextInputValidator,
	formatMessage
) {
	if (!event.value) {
		onSetIsValidNickname(true)
	} else if (
		textInputValidator(
			'nickName',
			event.value,
			onCustomTextInputValidator,
			formatMessage
		)
	) {
		onSetIsValidNickname(true)
		updateContractNickName(
			event,
			newlyAddedContracts,
			onUpdateNewlyAddedContracts
		)
	} else {
		onSetIsValidNickname(false)
	}
}

export function textInputValidator(
	elementId,
	inputValue,
	onCustomTextInputValidator,
	formatMessage
) {
	return (
		onCustomTextInputValidator(elementId, inputValue, formatMessage) === null
	)
}

export function updateContractNickName(event, contracts, updateContracts) {
	const updatedContract = contracts.find(
		contract => contract.id === event.rowData.itemKey
	)
	updatedContract.nickName = event.value
	const updatedContracts = [...new Set([...contracts, updatedContract])]
	updateContracts(updatedContracts)
}

export function toggleExpandedCell(item) {
	if (item.contractNumbersList) {
		return (
			<ul>
				{item.contractNumbersList.map(contractNumber => {
					return <li key={contractNumber}>{contractNumber}</li>
				})}
			</ul>
		)
	}
	return null
}

export function extraContent(formatMessage) {
	return {
		content() {
			return [
				{
					tag: 'BasicLabel',
					label: formatMessage({
						id:
							'secure-account-form-column-header.existing-contracts.contract-numbers.label'
					}),
					level: 0
				},
				{
					tag: 'BasicText',
					name: 'contractNumbersList',
					ordered: false
				}
			]
		}
	}
}

export function SecureAccountForm({
	mode,
	showSecureAccount,
	generalMessage,
	outageMessage,
	secureAccountFormIsValid,
	existingContracts,
	newContracts,
	newlyAddedContracts,
	modeOfAddingContracts,
	secureAccountSubmitted,
	secureAccountSubmitButtonDisabled,
	isValidNickname,
	onHideSecureAccount,
	onSubmitContracts,
	onUpdateInput,
	onUpdateExistingContracts,
	onCustomTextInputValidator,
	onSetIsValidNickname,
	onUpdateNewlyAddedContracts,
	onSetModeOfAddingContracts,
	intl
}) {
	if (
		!showSecureAccount &&
		/\bshowSecureAccount=true\b/gi.test(document.URL) &&
		secureAccountSubmitted
	) {
		const splitUrl = document.URL.split(/(&|\?)?showSecureAccount=true/)
		const urlWithSecureAccountRemoved = splitUrl[0] + splitUrl[2]
		window.history.pushState(null, null, urlWithSecureAccountRemoved)
	}

	const { formatMessage } = intl
	return (
		<Panel
			className="SecureAccountForm"
			footer={
				<SecureAccountFormFooter
					onHideSecureAccount={onHideSecureAccount}
					onSubmitContracts={onSubmitContracts}
					secureAccountFormIsValid={secureAccountFormIsValid}
					isValidNickname={isValidNickname}
					formatMessage={formatMessage}
					secureAccountSubmitButtonDisabled={secureAccountSubmitButtonDisabled}
				/>
			}
			width={'800px'}
			onClose={onHideSecureAccount}
			open={showSecureAccount}
			title={formatMessage({ id: 'secure-account-form.title' })}
			trigger={<button style={{ display: 'none' }} />}
		>
			<SecureAccountFormContent
				mode={mode}
				existingContracts={existingContracts}
				newContracts={newContracts}
				newlyAddedContracts={newlyAddedContracts}
				modeOfAddingContracts={modeOfAddingContracts}
				formatMessage={formatMessage}
				onSetIsValidNickname={onSetIsValidNickname}
				onUpdateInput={onUpdateInput}
				onCustomTextInputValidator={onCustomTextInputValidator}
				onUpdateExistingContracts={onUpdateExistingContracts}
				onUpdateNewlyAddedContracts={onUpdateNewlyAddedContracts}
				onSetModeOfAddingContracts={onSetModeOfAddingContracts}
				generalMessage={generalMessage}
				outageMessage={outageMessage}
			/>
		</Panel>
	)
}

export function SecureAccountFormFooter({
	onHideSecureAccount,
	onSubmitContracts,
	secureAccountFormIsValid,
	formatMessage,
	isValidNickname,
	secureAccountSubmitButtonDisabled
}) {
	return (
		<div>
			<Button
				className="SecureAccountFooterSubmitButton"
				kind="primary"
				onClick={onSubmitContracts}
				disabled={
					!(secureAccountFormIsValid && isValidNickname) ||
					secureAccountSubmitButtonDisabled
				}
				label={formatMessage({
					id: 'secure-account-form-footer.submit-button.label'
				})}
			/>
			<Button
				className="SecureAccountFooterCancelButton"
				kind="chromeless"
				onClick={onHideSecureAccount}
				label={formatMessage({
					id: 'secure-account-form-footer.cancel-button.label'
				})}
			/>
		</div>
	)
}
