import {
	SHOW_TERMS_AND_POLICIES,
	HIDE_TERMS_AND_POLICIES,
	DECLINE_TERMS_AND_POLICIES_SUCCESS,
	SHOW_ESIGN_CONSENT,
	HIDE_ESIGN_CONSENT,
	DECLINE_E_SIGN_CONTENT,
	CANCEL_TERMS_AND_POLICIES
} from '../actions/termsAndPolicies'
import { SHOW_CREATE_USER_ID } from '../actions/login'

export const defaultState = {
	get registrationTerms() {
		return null
	},
	get borrowerTerms() {
		return null
	},
	get attorneyTerms() {
		return null
	},
	get nonCCTerms() {
		return null
	},
	get borrowerPrivacySecurityAndLegal() {
		return null
	},
	get attorneyPrivacySecurityAndLegal() {
		return null
	},
	get nonCCPrivacySecurityAndLegal() {
		return null
	},
	get showTermsAndPolicies() {
		return false
	},
	get showTermsFooter() {
		return false
	},
	get showEsignContent() {
		return false
	},
	get showESignFooter() {
		return false
	},
	get termType() {
		return 'attorneyPrivacySecurityAndLegal'
	}
}

export function termsAndPolicies(state = defaultState, action) {
	switch (action.type) {
		case SHOW_ESIGN_CONSENT:
			return {
				...state,
				showESignFooter: action.showESignFooter,
				termType: action.termType,
				showEsignContent: true,
				[action.termType]: action.content
			}
		case HIDE_ESIGN_CONSENT:
		case SHOW_CREATE_USER_ID:
			return {
				...state,
				showEsignContent: false,
				showTermsAndPolicies: false
			}
		case SHOW_TERMS_AND_POLICIES:
			return {
				...state,
				showTermsAndPolicies: true,
				showTermsFooter: action.showTermsFooter,
				termType: action.termType,
				showEsignContent: false,
				[action.termType]: action.content
			}
		case DECLINE_E_SIGN_CONTENT:
			return {
				...state,
				showEsignContent: false
			}
		case HIDE_TERMS_AND_POLICIES:
		case DECLINE_TERMS_AND_POLICIES_SUCCESS:
			return {
				...state,
				showTermsAndPolicies: false,
				showEsignContent: false
			}
		case CANCEL_TERMS_AND_POLICIES:
			return {
				...state,
				showTermsAndPolicies: false
			}
		default:
			return state
	}
}
