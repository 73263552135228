import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, TextInput } from '@wf/ria'
import {
	WFINFOLEASE,
	PLINFOLEASE,
	SBA,
	ONBOARD,
	RICOH,
	DLO,
	COBO,
	EASYCONNECT
} from '../businessSegmentBrandIds'
import { CustomerContractNumber } from './CustomerContractNumber'
import { InvoiceSerialNumber } from './InvoiceSerialNumber'
import { CustomerContractInvoiceNumberRICOH } from './CustomerContractInvoiceNumberRICOH'
import { SystemMessage } from './SystemMessages'

export function onFocusUserIdInput(selector) {
	const input = document.querySelector(selector)
	if (input) {
		input.focus()
	}
}

const emailRef = React.createRef()
const existingEmailRef = React.createRef()
const dobRef = React.createRef()

export function getRegistrationMessageId(mode, messageId) {
	if (
		mode !== SBA &&
		mode !== ONBOARD &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT &&
		messageId.includes('CLDP1017')
	) {
		return `wca-form.new-user-registration.email-validation-message-id`
	}
	return messageId
}

export function RegistrationForm({
	focusedElement,
	mode,
	formatMessage,
	email,
	existingEmail,
	dob,
	customerNumber,
	customerNumberRicoh,
	contractNumber,
	contractNumberRicoh,
	invoiceNumber,
	serialNumber,
	customerOrContract,
	contractOrInvoice,
	invoiceOrSerial,
	registrationMessage,
	customerNumberInputClasses,
	contractNumberInputClasses,
	customerNumberRicohInputClasses,
	contractNumberRicohInputClasses,
	invoiceNumberInputClasses,
	serialNumberInputClasses,
	registrationButtonDisabled,
	emailInputClasses,
	existingEmailInputClasses,
	hideFieldErrors,
	onSetHideFieldErrors,
	onChange,
	onFocusFactory,
	onSelectCustomerOrContract,
	onSelectInvoiceOrSerial,
	onSelectContractOrInvoice,
	onGetEmailConfirmation,
	onTextInputValidator
}) {
	const [emailKey, setEmailKey] = useState(new Date().getTime())
	const [existingEmailKey, setExistingEmailKey] = useState(new Date().getTime())
	const { show, iconKind, messageId } = registrationMessage
	const [showRegistrationMessage, setShowRegistrationMessage] = useState(show)
	const brandBasedMessageId = getRegistrationMessageId(mode, messageId)
	const showMessage =
		Array.isArray(messageId) &&
		messageId.every(message => message.includes('CLDP'))
			? true
			: messageId.includes('CLDP')
	const valueMissing = formatMessage({ id: 'required-field.empty.error.label' })
	useEffect(() => {
		if (
			focusedElement !== 'userEmail' &&
			focusedElement !== 'existingUserEmail' &&
			focusedElement !== 'dob' &&
			focusedElement !== 'customerNumber' &&
			focusedElement !== 'contractNumber' &&
			focusedElement !== 'customerNumberRicoh' &&
			focusedElement !== 'contractNumberRicoh' &&
			focusedElement !== 'invoiceNumber' &&
			focusedElement !== 'serialNumber'
		) {
			emailRef.current.validate('reset')
			if (mode === RICOH || mode === PLINFOLEASE || mode === WFINFOLEASE) {
				existingEmailRef.current.validate('reset')
			}
			const newKey = new Date().getTime()
			setEmailKey(newKey)
			setExistingEmailKey(newKey)
		}
	}, [focusedElement, setExistingEmailKey, setEmailKey, mode])

	useEffect(() => {
		if (hideFieldErrors.email) {
			emailRef.current.validate('reset')
			const resetHideFieldErrors = { email: false }
			onSetHideFieldErrors(resetHideFieldErrors)
		}
		if (hideFieldErrors.existingEmail) {
			existingEmailRef.current.validate('reset')
			const resetHideFieldErrors = { existingEmail: false }
			onSetHideFieldErrors(resetHideFieldErrors)
		}
	}, [onSetHideFieldErrors, hideFieldErrors])

	useEffect(() => {
		if (show) {
			onFocusUserIdInput('.RegistrationForm #email')
		}
	}, [show])

	function onContinueRegistrationClick() {
		const canContinue = !registrationButtonDisabled
		setShowRegistrationMessage(canContinue)
		if (canContinue) {
			return onGetEmailConfirmation()
		}
		onFocusUserIdInput('#userEmail')
		if (emailRef && emailRef.current) {
			emailRef.current.validate()
		}
		if (
			mode === SBA ||
			mode === ONBOARD ||
			mode === DLO ||
			mode === COBO ||
			mode === EASYCONNECT
		) {
			if (dobRef && dobRef.current) {
				dobRef.current.validate()
			}
		}
	}

	switch (mode) {
		case RICOH:
			return (
				<div className="RegistrationForm">
					<div className="wf-row">
						<div className="wf-col--12">
							<SystemMessage
								className="RegistrationMessage"
								show={showMessage}
								iconKind={iconKind}
								messageId={brandBasedMessageId}
							/>
						</div>
					</div>
					<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group WcaRegistrationFormGroup">
						<div className="wf-col">
							<label htmlFor="userEmail">
								<FormattedMessage id="wca-form.new-user-registration.email.label" />
							</label>
						</div>
						<div className="wf-col">
							<TextInput
								key={emailKey}
								aria-label={formatMessage({
									id: 'wca-form.new-user-registration.email.label'
								})}
								aria-required={true}
								required={true}
								labelHidden={true}
								id="userEmail"
								ref={emailRef}
								type="text"
								size="25"
								value={email.value}
								onChange={onChange}
								onFocus={onFocusFactory('WcaRegister')}
								className={emailInputClasses}
								customValidators={[
									value => onTextInputValidator('emailInput', value)
								]}
								nativeValidationMessages={{
									valueMissing
								}}
							/>
						</div>
					</div>
					<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group WcaRegistrationFormGroup">
						<div className="wf-col">
							<label htmlFor="existingUserEmail">
								<FormattedMessage id="wca-form.new-user-registration.existingEmail.label" />
							</label>
						</div>
						<div className="wf-col">
							<TextInput
								key={existingEmailKey}
								aria-label={formatMessage({
									id: 'wca-form.new-user-registration.existingEmail.label'
								})}
								aria-required={true}
								required={true}
								labelHidden={true}
								id="existingUserEmail"
								ref={existingEmailRef}
								type="text"
								size="25"
								value={existingEmail.value}
								onChange={onChange}
								onFocus={onFocusFactory('WcaRegister')}
								className={existingEmailInputClasses}
								customValidators={[
									value => onTextInputValidator('existingEmailInput', value)
								]}
								nativeValidationMessages={{
									valueMissing
								}}
							/>
						</div>
					</div>
					<CustomerContractInvoiceNumberRICOH
						focusedElement={focusedElement}
						mode={mode}
						formatMessage={formatMessage}
						customerNumberRicoh={customerNumberRicoh}
						contractNumberRicoh={contractNumberRicoh}
						invoiceNumber={invoiceNumber}
						contractOrInvoice={contractOrInvoice}
						customerNumberInputClasses={customerNumberInputClasses}
						contractNumberInputClasses={contractNumberInputClasses}
						customerNumberRicohInputClasses={customerNumberRicohInputClasses}
						contractNumberRicohInputClasses={contractNumberRicohInputClasses}
						invoiceNumberInputClasses={invoiceNumberInputClasses}
						onChange={onChange}
						onFocusFactory={onFocusFactory}
						onSelectContractOrInvoice={onSelectContractOrInvoice}
						emailInputClasses={emailInputClasses}
						onTextInputValidator={onTextInputValidator}
					/>
					<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
						<div className="wf-col" />
						<div className="wf-col">
							<Button
								className="RegistrationButton"
								kind="primary"
								responsive
								onClick={onContinueRegistrationClick}
							>
								<FormattedMessage id="wca-form.new-user-registration.button.continue.label" />
							</Button>
						</div>
					</div>
				</div>
			)
		case PLINFOLEASE:
		case WFINFOLEASE:
			return (
				<div className="RegistrationForm">
					<div className="wf-row">
						<div className="wf-col--12">
							<SystemMessage
								className="RegistrationMessage"
								show={showMessage}
								iconKind={iconKind}
								messageId={brandBasedMessageId}
							/>
						</div>
					</div>
					<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group WcaRegistrationFormGroup">
						<div className="wf-col">
							<label htmlFor="userEmail">
								<FormattedMessage id="wca-form.new-user-registration.email.label" />
							</label>
						</div>
						<div className="wf-col">
							<TextInput
								key={emailKey}
								aria-label={formatMessage({
									id: 'wca-form.new-user-registration.email.label'
								})}
								labelHidden={true}
								aria-required={true}
								required={true}
								id="userEmail"
								ref={emailRef}
								type="text"
								size="25"
								value={email.value}
								onChange={onChange}
								onFocus={onFocusFactory('WcaRegister')}
								className={emailInputClasses}
								customValidators={[
									value => onTextInputValidator('emailInput', value)
								]}
								nativeValidationMessages={{
									valueMissing
								}}
							/>
						</div>
					</div>
					<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group WcaRegistrationFormGroup">
						<div className="wf-col">
							<label htmlFor="existingUserEmail">
								<FormattedMessage id="wca-form.new-user-registration.existingEmail.label" />
							</label>
						</div>
						<div className="wf-col">
							<TextInput
								key={existingEmailKey}
								aria-label={formatMessage({
									id: 'wca-form.new-user-registration.existingEmail.label'
								})}
								aria-required={true}
								required={true}
								labelHidden={true}
								id="existingUserEmail"
								ref={existingEmailRef}
								type="text"
								size="25"
								value={existingEmail.value}
								onChange={onChange}
								onFocus={onFocusFactory('WcaRegister')}
								className={existingEmailInputClasses}
								customValidators={[
									value => onTextInputValidator('existingEmailInput', value)
								]}
								nativeValidationMessages={{
									valueMissing
								}}
							/>
						</div>
					</div>
					<CustomerContractNumber
						focusedElement={focusedElement}
						mode={mode}
						formatMessage={formatMessage}
						customerNumber={customerNumber}
						contractNumber={contractNumber}
						customerOrContract={customerOrContract}
						customerNumberInputClasses={customerNumberInputClasses}
						contractNumberInputClasses={contractNumberInputClasses}
						onChange={onChange}
						onFocusFactory={onFocusFactory}
						onSelectCustomerOrContract={onSelectCustomerOrContract}
						onTextInputValidator={onTextInputValidator}
					/>
					<InvoiceSerialNumber
						focusedElement={focusedElement}
						mode={mode}
						formatMessage={formatMessage}
						invoiceNumber={invoiceNumber}
						serialNumber={serialNumber}
						invoiceOrSerial={invoiceOrSerial}
						invoiceNumberInputClasses={invoiceNumberInputClasses}
						serialNumberInputClasses={serialNumberInputClasses}
						onChange={onChange}
						onFocusFactory={onFocusFactory}
						onSelectInvoiceOrSerial={onSelectInvoiceOrSerial}
						emailInputClasses={emailInputClasses}
						onTextInputValidator={onTextInputValidator}
					/>
					<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
						<div className="wf-col" />
						<div className="wf-col">
							<Button
								className="RegistrationButton"
								kind="primary"
								responsive
								onClick={onContinueRegistrationClick}
							>
								<FormattedMessage id="wca-form.new-user-registration.button.continue.label" />
							</Button>
						</div>
					</div>
				</div>
			)

		case SBA:
		case ONBOARD:
		case DLO:
		case COBO:
		case EASYCONNECT:
			return (
				<div className="RegistrationForm form-group">
					<div className="wf-row">
						<div className="wf-col--12">
							<SystemMessage
								className="RegistrationMessage"
								show={showRegistrationMessage}
								iconKind={iconKind}
								messageId={brandBasedMessageId}
							/>
						</div>
					</div>
					<label htmlFor="userEmail">
						<FormattedMessage id="wca-form.new-user-registration.email.label" />
					</label>
					<div className="wf-row RegistrationInputEmail">
						<TextInput
							key={emailKey}
							aria-label={formatMessage({
								id: 'wca-form.new-user-registration.email.aria-label'
							})}
							aria-required={true}
							required={true}
							labelHidden={true}
							id="userEmail"
							ref={emailRef}
							type="text"
							size="25"
							value={email.value}
							onChange={onChange}
							onFocus={onFocusFactory('WcaRegister')}
							className={emailInputClasses}
							labelClassName="wf-col wf-col--12 wf-col--mob-only-12"
							fieldClassName="wf-col wf-col--12 wf-col--mob-only-12"
							nativeValidationMessages={{
								valueMissing
							}}
							customValidators={[
								value => onTextInputValidator('emailInput', value)
							]}
						/>
					</div>
					<label htmlFor="dob">
						<FormattedMessage id="wca-form.new-user-registration.dob.label" />
					</label>
					<div className="wf-row RegistrationInputDOB">
						<TextInput
							id="dob"
							type="text"
							aria-label={formatMessage({
								id: 'wca-dob.aria.label'
							})}
							required={true}
							labelHidden={true}
							placeholder="mm/dd"
							ref={dobRef}
							value={dob.value}
							onFocus={onFocusFactory('WcaRegister')}
							onChange={onChange}
							customValidators={[
								value => onTextInputValidator('dobInput', value)
							]}
						/>
					</div>
					<div className="wf-row">
						<div className="wf-col--mob-only-3 wf-col--tab-12 wf-col--desk-8">
							<Button
								className="RegistrationButton"
								kind="primary"
								disabled={messageId.includes('CLDP1029')}
								onClick={onContinueRegistrationClick}
							>
								<FormattedMessage id="wca-form.new-user-registration.button.continue.label" />
							</Button>
						</div>
					</div>
				</div>
			)

		default:
			return (
				<div className="RegistrationForm form-group">
					<div className="wf-row">
						<div className="wf-col--12">
							<SystemMessage
								className="RegistrationMessage"
								show={show}
								iconKind={iconKind}
								messageId={brandBasedMessageId}
							/>
						</div>
					</div>
					<div className="wf-row RegistrationForm">
						<TextInput
							key={emailKey}
							aria-label={formatMessage({
								id: 'wca-form.new-user-registration.email.label'
							})}
							aria-required={true}
							required={false}
							id="userEmail"
							ref={emailRef}
							type="text"
							size="25"
							value={email.value}
							onChange={onChange}
							onFocus={onFocusFactory('WcaRegister')}
							className={emailInputClasses}
							customValidators={[
								value => onTextInputValidator('emailInput', value)
							]}
							labelClassName="wf-col wf-col--12 wf-col--mob-only-12"
							fieldClassName="wf-col wf-col--12 wf-col--mob-only-12"
							nativeValidationMessages={{
								valueMissing
							}}
						/>
					</div>
					<div className="wf-row">
						<div className="wf-col--mob-only-3 wf-col--tab-12 wf-col--desk-8">
							<Button
								className="RegistrationButton"
								kind="primary"
								responsive
								onClick={onContinueRegistrationClick}
							>
								<FormattedMessage id="wca-form.new-user-registration.button.continue.label" />
							</Button>
						</div>
					</div>
				</div>
			)
	}
}
