import {
	SHOW_SIGN_ON_ERROR_MESSAGE,
	SHOW_REGISTRATION_ERROR_MESSAGE,
	SHOW_CREATE_USER_ID_ERROR_MESSAGE,
	SHOW_FORGOT_USER_ID_MESSAGE,
	SHOW_GENERAL_MESSAGE,
	SHOW_GENERAL_ERROR_MESSAGE,
	SHOW_OUTAGE_MESSAGE,
	SHOW_FEEDBACK_MESSAGE,
	HIDE_ALL_MESSAGES,
	HIDE_SIGNON_MESSAGES,
	HIDE_REGISTRATION_MESSAGES
} from '../actions/messages'

export const defaultState = {
	get generalMessage() {
		return {
			get messageId() {
				return ''
			},
			get show() {
				return false
			},
			get iconKind() {
				return ''
			},
			get values() {
				return {}
			}
		}
	},
	get signOnMessage() {
		return {
			get messageId() {
				return ''
			},
			get show() {
				return false
			},
			get iconKind() {
				return ''
			},
			get values() {
				return {}
			}
		}
	},
	get registrationMessage() {
		return {
			get messageId() {
				return ''
			},
			get show() {
				return false
			},
			get iconKind() {
				return ''
			},
			get values() {
				return {}
			}
		}
	},
	get createUserIdMessage() {
		return {
			get messageId() {
				return ''
			},
			get show() {
				return false
			},
			get iconKind() {
				return ''
			},
			get values() {
				return {}
			}
		}
	},
	get forgotUserIdMessage() {
		return {
			get messageId() {
				return ''
			},
			get show() {
				return false
			},
			get iconKind() {
				return ''
			},
			get values() {
				return {}
			}
		}
	},
	get outageMessage() {
		return {
			get message() {
				return ''
			},
			get show() {
				return false
			},
			get iconKind() {
				return ''
			}
		}
	},
	get feedbackMessage() {
		return {
			get message() {
				return ''
			},
			get show() {
				return false
			},
			get iconKind() {
				return ''
			}
		}
	}
}

export function messages(state = defaultState, action) {
	switch (action.type) {
		case SHOW_SIGN_ON_ERROR_MESSAGE:
			return {
				...state,
				signOnMessage: {
					show: true,
					iconKind: 'error',
					messageId: action.signOnError
				}
			}

		case SHOW_REGISTRATION_ERROR_MESSAGE:
			return {
				...state,
				registrationMessage: {
					show: true,
					iconKind: 'error',
					messageId: action.registrationError
				}
			}

		case SHOW_CREATE_USER_ID_ERROR_MESSAGE:
			return {
				...state,
				createUserIdMessage: {
					show: true,
					iconKind: 'error',
					messageId: action.createUserIdError
				}
			}

		case SHOW_GENERAL_MESSAGE:
			return {
				...state,
				generalMessage: {
					show: true,
					iconKind: action.iconKind,
					messageId: action.messageId
				}
			}

		case SHOW_FORGOT_USER_ID_MESSAGE:
			return {
				...state,
				forgotUserIdMessage: {
					show: true,
					iconKind: 'error',
					messageId: action.forgotUserIdError
				}
			}

		case SHOW_GENERAL_ERROR_MESSAGE:
			return {
				...state,
				generalMessage: {
					show: true,
					iconKind: 'error',
					messageId: action.generalErrorMessage
				}
			}

		case SHOW_OUTAGE_MESSAGE:
			return {
				...state,
				outageMessage: {
					show: true,
					iconKind: 'outage',
					message: action.outageMessageText
				}
			}
		case SHOW_FEEDBACK_MESSAGE:
			return {
				...state,
				feedbackMessage: {
					show: true,
					iconKind: 'error',
					message: action.feedbackMessage
				}
			}

		case HIDE_ALL_MESSAGES:
			return {
				...state,
				generalMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				},
				registrationMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				},
				createUserIdMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				},
				forgotUserIdMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				},
				signOnMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				},
				feedbackMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				},
				outageMessage: {
					show: false,
					iconKind: '',
					message: ''
				}
			}

		case HIDE_REGISTRATION_MESSAGES:
			return {
				...state,
				registrationMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				}
			}
		case HIDE_SIGNON_MESSAGES:
			return {
				...state,
				signOnMessage: {
					show: false,
					iconKind: '',
					messageId: '',
					values: {}
				}
			}

		default:
			return state
	}
}
