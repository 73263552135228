import 'url-search-params-polyfill'
import React from 'react'
import {
	setComponentDetailsOutage,
	composeMessageId,
	initializeWca,
	showLogin,
	setOnlineHelpUrl,
	getContracts
} from './actions/login'
import {
	showGeneralMessage,
	showSignOnErrorMessage,
	showOutageMessage
} from './actions/messages'
import {
	createBobWidgetFactory,
	getBobLoginWidgetForOrigin
} from './createBobWidget'
import {
	setSignOnLocaleUrl,
	showLanguageDropDown,
	changeLanguage
} from './actions/locale'
import { BOB } from './components/BOB'
import WCA from './containers/WCA'
import Faqs from './containers/Faqs'
import Security from './OCRA/containers/Security'
import {
	DOCEX,
	WFORMS,
	RFI,
	ONBOARD,
	SBA,
	DLO,
	COBO,
	EASYCONNECT
} from './businessSegmentBrandIds'
import { createWcaWidget } from './createWcaWidget'
import { createWcaPingWidget } from './createWcaPingWidget'

export function setSrc(id, src) {
	const element = document.getElementById(id)
	if (element && !element.src) {
		element.setAttribute('src', src)
	}
}

export function selectRequiredWidget(mode, auth, productName) {
	if (/\bOCRA_Security/gi.test(document.URL)) {
		document.title = 'OCRA Security Center'
		return 'OCRA_Security'
	}
	if (/\bfaqs\b/gi.test(document.URL)) {
		switch (mode) {
			case RFI:
				document.title =
					'Compliance Information Requests - Frequently Asked Questions'
				return 'faqs'
			case WFORMS:
			case DOCEX:
				document.title = 'Lending Center- Frequently Asked Questions'
				return 'faqs'
			case EASYCONNECT:
				document.title = 'Easy Connect- Frequently Asked Questions'
				return 'faqs'
			default:
				document.title = 'Lending Management - Frequently Asked Questions'
				return 'faqs'
		}
	} else {
		document.title = productName
	}
	return auth
}

export function getPageMode() {
	if (document.URL.includes('wforms')) {
		return 'wforms'
	}
	if (document.URL.includes('docex')) {
		return 'docex'
	}
	if (document.URL.includes('rfi')) {
		return 'rfi'
	}
	return ''
}

export function setLoginWidget(store, authParam, componentDetailsError) {
	const { dispatch, getState } = store
	if (componentDetailsError) {
		dispatch(setComponentDetailsOutage())
		return <WCA name={'wca'} />
	}
	const {
		wca,
		outageManagement,
		auth
	} = getState().loginWidget.componentDetails
	const { mode } = getState().loginWidget

	if (authParam === 'OCRA_Security') {
		return <Security />
	}
	if (outageManagement && outageManagement.outageManagementFlag === 'Y') {
		dispatch(showOutageMessage(outageManagement.outageMessageText))
	}
	if (auth === 'bob') {
		return <BOB />
	} else if (authParam === 'faqs') {
		const mode = getPageMode()
		return <Faqs name={'faqs'} mode={mode} />
	}
	if (
		mode !== DOCEX &&
		mode !== WFORMS &&
		mode !== ONBOARD &&
		mode !== SBA &&
		mode !== RFI &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT
	) {
		checkSecureAccountParam(dispatch)
	}
	const wcaWidget = wca.wcaPingEnabled
		? createWcaPingWidget(wca, mode)
		: createWcaWidget(wca, mode)
	dispatch(initializeWca(wcaWidget))
	return <WCA name={'wca'} />
}

export function getSignOnHelpUrl(pathname) {
	let urlParams = ''
	if (/\bwforms\b/gi.test(document.URL)) {
		urlParams = '?faqs&mode=wforms'
	} else if (/\bdocex\b/gi.test(document.URL)) {
		urlParams = '?faqs&mode=docex'
	} else if (/\brfi\b/gi.test(document.URL)) {
		urlParams = '?faqs&mode=rfi'
	} else if (/\beasyconnect\b/gi.test(document.URL)) {
		urlParams = '?faqs&mode=easyconnect'
	} else if (/\blocale=fr\b/gi.test(document.URL)) {
		urlParams = '?faqs&mode=canada&locale=fr'
	} else if (/\bocra/.test(document.URL)) {
		const params = new URLSearchParams(window.location.search)
		const mode = params.get('mode')
		urlParams = `?faqs&mode=${mode}`
		if (/\blocale=fr_CA\b/gi.test(document.URL)) {
			urlParams = `?faqs&mode=${mode}&locale=fr_CA`
		}
		if (
			/\blocale=en_US\b/gi.test(document.URL) ||
			/\blocale=en_GB\b/gi.test(document.URL)
		) {
			urlParams = `?faqs&mode=${mode}&locale=en`
		}
		if (/\blocale=fr_FR\b/gi.test(document.URL)) {
			urlParams = `?faqs&mode=${mode}&locale=fr_FR`
		}
		if (/\blocale=de_DE\b/gi.test(document.URL)) {
			urlParams = `?faqs&mode=${mode}&locale=de_DE`
		}
	} else {
		urlParams = '?faqs'
	}
	if (/index\.html/.test(pathname)) {
		return `${pathname}${urlParams}`
	}
	return `${pathname}index.html${urlParams}`
}

export function wcaErrorMessagesListener(
	dispatch,
	_wcaErrorMessagesObserver = wcaErrorMessagesObserver
) {
	_wcaErrorMessagesObserver(dispatch, message => {
		dispatch(showSignOnErrorMessage(message))
	})
}

export function focusUserIdInput() {
	const input = document.getElementById('loginUserId')
	if (input) {
		input.focus()
	}
}

export function wcaErrorMessagesObserver(dispatch, onWcaErrorMessage) {
	const checkWcaErrorMessageInterval = 200
	const wcaErrorMessage = document.getElementById('wcaErrorMessages')

	function checkForWcaErrorMessage() {
		if (wcaErrorMessage && wcaErrorMessage.innerText) {
			onWcaErrorMessage(wcaErrorMessage.innerText)
			wcaErrorMessage.innerText = ''
			const nextTick = 100
			setTimeout(focusUserIdInput, nextTick)
		}
		setTimeout(checkForWcaErrorMessage, checkWcaErrorMessageInterval)
	}

	checkForWcaErrorMessage()
}

export function runTimeout(
	store,
	renderDom,
	componentDetailsError,
	authParam,
	_setLoginWidget = setLoginWidget,
	_createBobWidgetFactory = createBobWidgetFactory,
	_getBobLoginWidgetForOrigin = getBobLoginWidgetForOrigin,
	_wcaErrorMessagesListener = wcaErrorMessagesListener,
	_wcaErrorMessagesObserver = wcaErrorMessagesObserver,
	_getSignOffRedirectMessage = getSignOffRedirectMessage
) {
	if (componentDetailsError) {
		const widget = _setLoginWidget(store, authParam, componentDetailsError)
		store.dispatch(showLogin(widget))
		renderDom()
	} else {
		if (authParam === 'faqs') {
			const widget = _setLoginWidget(store, authParam)
			store.dispatch(showLogin(widget))
			renderDom()
		}
		if (authParam === 'OCRA_Security') {
			const widget = _setLoginWidget(store, authParam)
			store.dispatch(showLogin(widget))
			renderDom()
		}
		const checkReadyTimeout = 200
		let widgetsReadyTimeoutId
		widgetsReadyTimeoutId = setTimeout(() => {
			const requiredWidget = authParam === 'bob' ? 'auth' : 'WCAWidget'
			if (window.hasOwnProperty(requiredWidget)) {
				const widget = _setLoginWidget(store, authParam, componentDetailsError)
				store.dispatch(showLogin(widget))
				if (requiredWidget === 'auth') {
					const { bob } = store.getState().loginWidget.componentDetails
					const { baseUrl, forgotUsernameUrl, bobServiceType } = bob
					_createBobWidgetFactory(
						baseUrl,
						_getBobLoginWidgetForOrigin(
							baseUrl,
							forgotUsernameUrl,
							bobServiceType
						)
					)
				} else if (requiredWidget === 'WCAWidget') {
					_wcaErrorMessagesListener(store.dispatch, _wcaErrorMessagesObserver)
				}
				renderDom()
				_getSignOffRedirectMessage(store.dispatch)
			} else {
				runTimeout(
					store,
					renderDom,
					componentDetailsError,
					authParam,
					_setLoginWidget,
					_createBobWidgetFactory,
					_getBobLoginWidgetForOrigin,
					_wcaErrorMessagesListener,
					_wcaErrorMessagesObserver
				)
			}
			clearTimeout(widgetsReadyTimeoutId)
		}, checkReadyTimeout)
	}
}

export function setMessageIconKind(messageCode) {
	switch (messageCode) {
		case 'CLDP1013':
			return 'confirmation'
		case 'CLDP1014':
			return 'timeout'
		case 'CLDP9998':
		case 'CLDP9999':
			return 'error'
		case 'CLDP1091':
		case 'CLDP1092':
		case 'CLDP1093':
		case 'CLDP1050':
			return 'warning'
		default:
			return 'confirmation'
	}
}

export function getSignOffRedirectMessage(dispatch) {
	const queryParamsArray = window.location.search.substring(1).split('&')
	let queryParamsObject = {}
	queryParamsArray.forEach(param => {
		const paramPair = param.split('=')
		queryParamsObject[paramPair[0]] = paramPair[1]
	})
	if (queryParamsObject.signOffRedirectMessage) {
		const iconKind = setMessageIconKind(
			queryParamsObject.signOffRedirectMessage
		)
		setTimeout(() => {
			dispatch(
				showGeneralMessage(
					composeMessageId(queryParamsObject.signOffRedirectMessage),
					iconKind
				)
			)
		}, 100)
		const { href } = window.location
		const newHref = href
			.split(
				`signOffRedirectMessage=${queryParamsObject.signOffRedirectMessage}`
			)
			.join('')
			.replace('&&', '&')
			.replace(/&$/, '')
		window.history.replaceState({}, '', newHref)
	}
}

export function appInit(
	store,
	renderDom,
	componentDetailsError,
	_selectRequiredWidget = selectRequiredWidget,
	_setSrc = setSrc,
	_runTimeout = runTimeout
) {
	const {
		bob,
		wca,
		productName,
		mode,
		auth
	} = store.getState().loginWidget.componentDetails
	const authParam = selectRequiredWidget(mode, auth, productName)
	if (authParam === 'bob') {
		_setSrc('bobScript', bob.scriptSrc)
		_setSrc('j_authBridge', bob.iframeSrc)
	}
	if (authParam === 'OCRA_Security') {
		const searchUrl = window.location.search
		const params = new URLSearchParams(searchUrl)
		const locale = convertLocaleForOCRA(
			params.get('locale'),
			params.get('mode')
		)
		store.dispatch(changeLanguage(locale))
		_setSrc('wcaScript', wca.scriptSrc)
	} else {
		const { pathname } = document.location
		const url = getSignOnHelpUrl(pathname)
		store.dispatch(setOnlineHelpUrl(url))
		/* set action for signonLocale Url */
		const searchUrl = window.location.search
		const signOnLocaleUrl = getSignOnLocaleUrl(store, searchUrl)
		store.dispatch(setSignOnLocaleUrl(signOnLocaleUrl))
		/* end of  set action for signonLocale Url */
		_setSrc('wcaScript', wca.scriptSrc)
	}

	_runTimeout(
		store,
		renderDom,
		componentDetailsError,
		authParam,
		setLoginWidget,
		createBobWidgetFactory,
		getBobLoginWidgetForOrigin,
		wcaErrorMessagesListener,
		wcaErrorMessagesObserver
	)
}

export function checkSecureAccountParam(dispatch) {
	if (/\bshowSecureAccount=true\b/gi.test(document.URL)) {
		dispatch(getContracts())
	}
}

export function convertLocaleForOCRA(locale, mode) {
	if (!locale && mode && mode.includes('EMEA')) {
		return 'en_GB'
	}
	if (!locale && mode && mode.includes('ANZ')) {
		return 'au_AU'
	}
	switch (locale) {
		case 'en_US':
			return 'en'
		case 'fr_CA':
			return 'fr'
		case 'de_DE':
			return 'de_DE'
		case 'fr_FR':
			return 'fr_FR'
		case 'en_GB':
			return 'en_GB'
		case 'au_AU':
			return 'au_AU'
		default:
			return 'en'
	}
}

/* Function used to set url for US and Canada Sign-on Here */
export function getSignOnLocaleUrl(store, searchUrl) {
	// check if canada exist in url
	const params = new URLSearchParams(searchUrl)
	if (params.has('mode') && params.get('mode') === 'canada') {
		// enable flag true to show dropwdowm menu for Canada users
		if (
			params.has('locale') &&
			(params.get('locale') === 'en' || params.get('locale') === 'fr')
		) {
			store.dispatch(changeLanguage(params.get('locale')))
		} else {
			// adding else for users - accidently trying locale other than en or es
			// adding by default en in store
			store.dispatch(changeLanguage('en'))
		}
		store.dispatch(showLanguageDropDown(true))
		// Spliting url for US users Sign-on here
		if (params.has('locale')) {
			params.delete('locale')
			params.delete('mode')
		} else {
			params.delete('mode')
		}

		const paramsKeys = []
		for (var key of params.keys()) {
			paramsKeys.push(key)
		}

		/* if length greater than zero then need to add ? search query string */
		if (paramsKeys.length > 0) {
			const newURL = '?' + params.toString()
			return `${newURL}`
		} else {
			const newURL = document.location.href.replace(
				document.location.search,
				''
			)
			return `${newURL}`
		}
	} else if (params.has('mode') && params.get('mode').includes('ocra')) {
		// For OCRA signon
		const locale = convertLocaleForOCRA(
			params.get('locale'),
			params.get('mode')
		)
		store.dispatch(changeLanguage(locale))
		const newURL = '?' + params.toString()
		return `${newURL}`
	} else {
		// enable flag false to hide dropwdowm menu for US users
		store.dispatch(showLanguageDropDown(false))
		// Creating  url for Canada users Sign-on here
		params.append('mode', 'canada')
		params.append('locale', 'en')
		const newURL = '?' + params.toString()
		return `${newURL}`
	}
}
