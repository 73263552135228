import { POST } from 'ecpfs-authority'
import { WFINFOLEASE, PLINFOLEASE, RICOH } from '../businessSegmentBrandIds'

export const GET_CONTRACTS_PATH_INFOLEASE =
	'register/ils/v1/manageAccounts.json'
export const GET_CONTRACTS_PATH_RICOH = 'ricoh/register/v1/groups.json'
export const GET_CONTRACTS_PATH_PRIVATE_LABEL =
	'register/ils/v1/manageAccounts.json'

export function fetchContracts(mode) {
	const PATH = getPrivacyPolicyPathByBrandId(mode)
	return POST(PATH, {})
		.then(fetchContractsResponse => {
			if (fetchContractsResponse.success) {
				let contracts
				if (mode === PLINFOLEASE || mode === WFINFOLEASE) {
					const {
						registeredAccountProfileList,
						customerAccountProfileList
					} = fetchContractsResponse
					contracts = {
						existingContracts: constructWFPLAccountsData(
							registeredAccountProfileList
						),
						newContracts: constructWFPLAccountsData(customerAccountProfileList)
					}
				} else {
					const {
						registeredContractList,
						unRegisteredContractList
					} = fetchContractsResponse
					contracts = {
						existingContracts: constructRICOHAccountsData(
							registeredContractList
						),
						newContracts: constructRICOHAccountsData(unRegisteredContractList)
					}
				}
				return new Promise(resolve => {
					resolve({ fetchContractsResponse, contracts })
				})
			} else {
				return new Promise(resolve => {
					resolve({ fetchContractsResponse })
				})
			}
		})
		.catch(fetchContractsError => {
			return new Promise((resolve, reject) => {
				reject(fetchContractsError)
			})
		})
}

export function getPrivacyPolicyPathByBrandId(mode) {
	switch (mode) {
		case WFINFOLEASE:
			return GET_CONTRACTS_PATH_INFOLEASE
		case RICOH:
			return GET_CONTRACTS_PATH_RICOH
		default:
			return GET_CONTRACTS_PATH_PRIVATE_LABEL
	}
}

export function constructWFPLAccountsData(accountsList) {
	return accountsList.map(account => {
		const { contractNbrs } = account
		const hasMultipleContracts = contractNbrs.length > 1
		const contractNumbers = hasMultipleContracts ? 'Multiple' : contractNbrs[0]
		const contractNumbersList = hasMultipleContracts ? contractNbrs : null
		const id = hasMultipleContracts
			? contractNumbersList.toString()
			: contractNumbers
		const { zipCode, nickName } = account
		return {
			id,
			contractNumbers,
			contractNumbersList,
			zipCode,
			nickName
		}
	})
}

export function constructRICOHAccountsData(accountsList) {
	return accountsList.map(account => {
		const contractIds = account.contractList.map(
			contract => contract.contractId
		)
		const hasMultipleContracts = contractIds.length > 1
		const contractNumbers = hasMultipleContracts ? 'Multiple' : contractIds[0]
		const contractNumbersList = hasMultipleContracts ? contractIds : null
		const id = hasMultipleContracts ? contractNumbersList : contractNumbers
		return {
			id,
			contractNumbers,
			contractNumbersList,
			zipCode: '',
			nickName: ''
		}
	})
}
