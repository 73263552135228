import { combineReducers } from 'redux'
import { loginWidget } from './login'
import { messages } from './messages'
import { termsAndPolicies } from './termsAndPolicies'
import { locale } from './locale'
import { ocra } from '../OCRA/reducers'
export default combineReducers({
	loginWidget,
	messages,
	termsAndPolicies,
	locale,
	ocra
})
