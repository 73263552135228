import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { SecureAccountForm } from '../components/SecureAccountForm'
import {
	updateExistingContracts,
	updateNewlyAddedContracts,
	setModeOfAddingContracts,
	setIsValidNickname,
	submitContracts,
	hideSecureAccount
} from '../actions/login'

export const mapStateToProps = state => {
	const {
		existingContracts,
		newContracts,
		newlyAddedContracts,
		showSecureAccount,
		modeOfAddingContracts,
		isValidNickname,
		secureAccountSubmitted,
		secureAccountSubmitButtonDisabled
	} = state.loginWidget
	return {
		existingContracts,
		newContracts,
		newlyAddedContracts,
		showSecureAccount,
		modeOfAddingContracts,
		isValidNickname,
		secureAccountSubmitted,
		secureAccountSubmitButtonDisabled,
		secureAccountFormIsValid: modeOfAddingContracts !== '',
		mode: state.loginWidget.mode,
		generalMessage: state.messages.generalMessage,
		outageMessage: state.messages.outageMessage
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onUpdateExistingContracts(existingContracts) {
			dispatch(updateExistingContracts(existingContracts))
		},
		onUpdateNewlyAddedContracts(newlyAddedContracts) {
			dispatch(updateNewlyAddedContracts(newlyAddedContracts))
		},
		onSetModeOfAddingContracts(modeOfAddingContracts) {
			dispatch(setModeOfAddingContracts(modeOfAddingContracts))
		},
		onSetIsValidNickname(isValidNickname) {
			dispatch(setIsValidNickname(isValidNickname))
		},
		onSubmitContracts() {
			dispatch(submitContracts())
		},
		onHideSecureAccount() {
			dispatch(hideSecureAccount())
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SecureAccountForm)
)
