import {
	CHANGE_LANGUAGE,
	SHOW_LANGUAGE_DROPDOWN,
	SET_SIGN_ON_LOCALE_URL
} from '../actions/locale'
export const defaultState = {
	locale: 'en',
	showLanguageDropDown: false,
	signOnLocaleUrl: ''
}

export function locale(state = defaultState, action) {
	switch (action.type) {
		case CHANGE_LANGUAGE:
			return {
				...state,
				locale: action.locale
			}
		case SHOW_LANGUAGE_DROPDOWN: // action to enable the header as per mode
			return {
				...state,
				showLanguageDropDown: action.languageDropDown
			}
		case SET_SIGN_ON_LOCALE_URL:
			return {
				...state,
				signOnLocaleUrl: action.signOnLocaleUrl
			}
		default:
			return state
	}
}
