import React from 'react'
import { FormattedMessage } from 'react-intl'
import EqualHousingLogo from '../Equal-Housing-Logo.png'
import {
	DOCEX,
	WFORMS,
	SBA,
	LGMTBOB,
	EASYCONNECT
} from '../businessSegmentBrandIds'

export function EqualHousing({ hideEqualHousing, mode }) {
	if (hideEqualHousing) {
		return null
	}
	if (
		mode === DOCEX ||
		mode === WFORMS ||
		mode === SBA ||
		mode === LGMTBOB ||
		mode === EASYCONNECT
	) {
		return (
			<div className="EqualHousing">
				<img src={EqualHousingLogo} alt="Equal housing logo" />
				<span>
					<FormattedMessage id="footer.legal.equal-housing-lender.text" />
				</span>
			</div>
		)
	} else {
		return null
	}
}
