export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export function changeLanguage(locale) {
	return {
		type: CHANGE_LANGUAGE,
		locale
	}
}

export const SHOW_LANGUAGE_DROPDOWN = 'SHOW_LANGUAGE_DROPDOWN'
export function showLanguageDropDown(languageDropDown) {
	return {
		type: SHOW_LANGUAGE_DROPDOWN,
		languageDropDown
	}
}

export const SET_SIGN_ON_LOCALE_URL = 'SET_SIGN_ON_LOCALE_URL'
export function setSignOnLocaleUrl(signOnLocaleUrl) {
	return {
		type: SET_SIGN_ON_LOCALE_URL,
		signOnLocaleUrl
	}
}
