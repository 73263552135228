import React, { Fragment } from 'react'
import { TextInput } from '@wf/ria'
import {
	SBA,
	ONBOARD,
	DLO,
	COBO,
	EASYCONNECT,
	PLINFOLEASE,
	RICOH
} from '../businessSegmentBrandIds'
import { SystemMessage } from './SystemMessages'

export function getfailedAttemptsMessageId(mode, messageId) {
	if (
		mode !== SBA &&
		mode !== ONBOARD &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT &&
		messageId.includes('CLDP1017')
	) {
		return `api-service-error.forgotUserId.CLDP1017.message`
	}
	if (messageId.includes('CLDP1029')) {
		if (mode === PLINFOLEASE) {
			return 'api-service-error.forgotUserId.pl.CLDP1029.message'
		} else if (mode === RICOH) {
			return 'api-service-error.forgotUserId.ricoh.CLDP1029.message'
		}
		return 'api-service-error.forgotUserId.wf.CLDP1029.message'
	}
	if (messageId.includes('CLDP1030')) {
		if (mode === PLINFOLEASE) {
			return 'api-service-error.forgotUserId.pl.CLDP1030.message'
		} else if (mode === RICOH) {
			return 'api-service-error.forgotUserId.ricoh.CLDP1030.message'
		}
		return 'api-service-error.forgotUserId.wf.CLDP1030.message'
	}
	return messageId
}
export function ForgotUserIdContentInput({
	forgotUserIdMessage,
	dob,
	emailForgotUser,
	emailForgotUserRef,
	dobForgotUserRef,
	formatMessage,
	onUpdateInput,
	onTextInputValidator,
	mode
}) {
	function onChange(event) {
		const id = event.target.id
		const value = event.target.value
		onUpdateInput(id, value)
	}

	const { messageId, iconKind, show } = forgotUserIdMessage

	const failedAttemptsMessageId = getfailedAttemptsMessageId(mode, messageId)
	if (
		mode === SBA ||
		mode === ONBOARD ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return (
			<Fragment>
				<div className="wf-row">
					<div className="wf-col--12">
						<SystemMessage
							className="ForgotUserIdMessage"
							show={show}
							iconKind={iconKind}
							messageId={failedAttemptsMessageId}
						/>
					</div>
				</div>
				<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns ">
					<div className="wf-col--3 wf-col--mob-only-6 ForgotUserIdInput">
						<TextInput
							label={formatMessage({
								id: 'forgot-user-id-form.form-content.email.label'
							})}
							aria-label={formatMessage({
								id: 'forgot-user-id-form.form-content.email.aria-label'
							})}
							ref={emailForgotUserRef}
							required={true}
							id="emailForgotUser"
							type="text"
							value={emailForgotUser.value}
							onChange={onChange}
							customValidators={[
								value => onTextInputValidator('emailForgotUser', value)
							]}
							className="api-xsmall-margin-bottom"
						/>
					</div>
				</div>
				<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns">
					<div className="wf-col--3 wf-col--mob-only-6 ForgotUserIdInput">
						<TextInput
							label={formatMessage({
								id: 'forgot-user-id-form.form-content.dob.label'
							})}
							aria-label={formatMessage({
								id: 'wca-dob.aria.label'
							})}
							id="dob"
							ref={dobForgotUserRef}
							required={true}
							placeholder="mm/dd"
							type="text"
							value={dob.value}
							onChange={onChange}
							customValidators={[value => onTextInputValidator('dob', value)]}
							className="api-xsmall-margin-bottom"
						/>
					</div>
				</div>
			</Fragment>
		)
	}
	return (
		<div className="ForgotUserIdInput">
			<div className="wf-row">
				<div className="wf-col--12">
					<SystemMessage
						className="ForgotUserIdMessage"
						show={show}
						iconKind={iconKind}
						messageId={failedAttemptsMessageId}
					/>
				</div>
			</div>
			<TextInput
				aria-label={formatMessage({
					id: 'forgot-user-id-form.form-content.email.aria-label'
				})}
				className="api-xsmall-margin-bottom"
				label={formatMessage({
					id: 'forgot-user-id-form.form-content.email.label'
				})}
				id="emailForgotUser"
				type="text"
				value={emailForgotUser.value}
				onChange={onChange}
				required={true}
				ref={emailForgotUserRef}
				aria-required={true}
				customValidators={[
					value => onTextInputValidator('emailForgotUser', value)
				]}
				nativeValidationMessages={{
					valueMissing: formatMessage({
						id: 'required-field.empty.error.label'
					})
				}}
				labelClassName="wf-row wf-row--desk-3 wf-row--tab-3 wf-row--mob-only-12"
				fieldClassName="wf-row wf-row--desk-2 wf-row--tab-4 wf-row--mob-only-12"
			/>
		</div>
	)
}
