import React from 'react'
import { FormattedMessage } from 'react-intl'

export function Security({ mode }) {
	if (mode === 'ocraNA' || mode === 'ocraANZ') {
		return (
			<div data-wf-efmya-ocra-id="securityheader-0">
				<h1>
					<FormattedMessage id="security.messages.header-ocraNA" />
				</h1>
				<p>
					<em>
						<FormattedMessage id="security.messages.safeguard-ocraNA" />
					</em>
				</p>
				<p>
					<FormattedMessage id="security.messages.foremost-ocraNA" />
				</p>
				<p>
					<FormattedMessage id="security.messages.additional-ocraNA" />
				</p>
				<h2>
					<FormattedMessage id="security.messages.h2-ocraNA" />
				</h2>
				<p>
					<FormattedMessage id="security.messages.sensetivmessage-ocraNA" />
				</p>
				<p>
					<em>
						<FormattedMessage id="security.messages.exampleshead-ocraNA" />
					</em>
				</p>
				<ol>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleshead-1-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examples-1-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleshead-2-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examples-2-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleshead-3-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examples-3-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleshead-4-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examples-4-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleshead-5-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examples-5-ocraNA" />
						</p>
					</li>
				</ol>

				<h2>
					<FormattedMessage id="security.messages.loginh2-ocraNA" />
				</h2>
				<p>
					<FormattedMessage id="security.messages.loginhead-ocraNA" />
				</p>

				<p>
					<em>
						<FormattedMessage id="security.messages.loginhead-1-ocraNA" />
					</em>
				</p>
				<p></p>
				<ol>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-1-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-1-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-2-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-2-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-3-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-3-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-4-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-4-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-5-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-5-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-6-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-6-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-7-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-7-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.exampleheadlogin-8-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.examplelogin-8-ocraNA" />
						</p>
					</li>
				</ol>

				<h2>
					<FormattedMessage id="security.messages.recommendations-ocraNA" />
				</h2>
				<p>
					<FormattedMessage id="security.messages.recommendations-secure-ocraNA" />
				</p>

				<ol>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.recommendations-1-head-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.recommendations-1-ocraNA" />
						</p>
						<ol>
							<li>
								<strong>
									<FormattedMessage id="security.messages.recommendations-1-A-head-ocraNA" />
								</strong>
								<FormattedMessage id="security.messages.recommendations-1-A-ocraNA" />
							</li>
							<li>
								<strong>
									<FormattedMessage id="security.messages.recommendations-1-B-head-ocraNA" />
								</strong>
								<FormattedMessage id="security.messages.recommendations-1-B-ocraNA" />
							</li>
							<p></p>
						</ol>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.recommendations-2-head-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.recommendations-2-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.recommendations-3-head-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.recommendations-3-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.recommendations-4-head-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.recommendations-4-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.recommendations-5-head-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.recommendations-5-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages.recommendations-6-head-ocraNA" />
							</strong>
							<FormattedMessage id="security.messages.recommendations-6-ocraNA" />
						</p>
					</li>
				</ol>

				<h2>
					<FormattedMessage id="security.messages.additional-head-ocraNA" />
				</h2>
				<p>
					<FormattedMessage id="security.messages.additional-head-message-1-ocraNA" />
				</p>

				<p>
					<em>
						<FormattedMessage id="security.messages.additional-head-message-2-ocraNA" />
					</em>
				</p>
				<p></p>
				<p>
					<em>
						<FormattedMessage id="security.messages.additional-us-ocraNA" />
					</em>
				</p>
				<ul>
					<li>
						<p>
							<strong>
								<a href="http://www.OnlineOnGuard.gov" target="blank">
									<FormattedMessage id="security.messages.additional-us-onguard-ocraNA" />
								</a>
							</strong>
						</p>
						<p>
							<FormattedMessage id="security.messages.additional-us-onguard-message-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<a href="http://www.staysafeonline.org" target="blank">
									<FormattedMessage id="security.messages.additional-us-staysafe-ocraNA" />
								</a>
							</strong>
						</p>
						<p>
							<FormattedMessage id="security.messages.additional-us-staysafe-message-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<a href="http://www.consumer.ftc.gov/topics/privacy-identity">
									<FormattedMessage id="security.messages.additional-us-consumer-ocraNA" />
								</a>
							</strong>
						</p>
						<p>
							<FormattedMessage id="security.messages.additional-us-consumer-message-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<a href="http://business.ftc.gov/privacy-and-security">
									<FormattedMessage id="security.messages.additional-us-ftc-ocraNA" />
								</a>
							</strong>
						</p>
						<p>
							<FormattedMessage id="security.messages.additional-us-ftc-message-ocraNA" />
						</p>
					</li>
				</ul>
				<p>
					<strong>
						<FormattedMessage id="security.messages.additional-suspect-ocraNA" />
					</strong>
				</p>
				<p>
					<em>
						<FormattedMessage id="security.messages.additional-us-ocraNA" />
					</em>
				</p>
				<ul>
					<li>
						<p>
							<FormattedMessage id="security.messages.additional-us-equifax-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<FormattedMessage id="security.messages.additional-us-transunion-ocraNA" />
						</p>
					</li>
					<li>
						<p>
							<FormattedMessage id="security.messages.additional-us-experian-ocraNA" />
						</p>
					</li>
				</ul>
				<p>
					<em>
						<FormattedMessage id="security.messages.additional-ca-ocraNA" />
					</em>
				</p>
				<ul>
					<li>
						<p>
							<FormattedMessage id="security.messages.additional-ca-equifax-phone-ocraNA" />
							<a href="https://www.equifax.ca">
								<FormattedMessage id="security.messages.additional-ca-equifax-url-ocraNA" />
							</a>
						</p>
					</li>
					<li>
						<p>
							<FormattedMessage id="security.messages.additional-ca-transunion-phone-ocraNA" />
							<a href="https://www.transunion.ca">
								<FormattedMessage id="security.messages.additional-ca-transunion-url-ocraNA" />
							</a>
						</p>
					</li>
					<li>
						<p>
							<FormattedMessage id="security.messages.additional-ca-experian-phone-ocraNA" />

							<a href="https://www.experian.com">
								<FormattedMessage id="security.messages.additional-ca-experian-url-ocraNA" />
							</a>
						</p>
					</li>
				</ul>
			</div>
		)
	}
	if (mode === 'ocraEMEA') {
		return (
			<div data-wf-efmya-ocra-id="securityheader-1">
				<h1>
					<FormattedMessage id="security.messages.header-ocraEMEA" />
				</h1>
				<p>
					<em>
						<FormattedMessage id="security.messages.header-message-1-ocraEMEA" />
					</em>
				</p>
				<p>
					<FormattedMessage id="security.messages.header-message-2-ocraEMEA" />
				</p>
				<p>
					<FormattedMessage id="security.messages.header-message-3-ocraEMEA" />
				</p>
				<h2>
					<FormattedMessage id="security.messages.header-protect-ocraEMEA" />
				</h2>
				<p>
					<FormattedMessage id="security.messages.header-protect-message-ocraEMEA" />
				</p>

				<p data-wf-efmya-ocra-id="securityEmeaSection">
					<b>
						<span>
							<FormattedMessage id="security.messages.header-privacy-ocraEMEA" />
						</span>
					</b>
				</p>

				<p data-wf-efmya-ocra-id="securityEmeaSectionParagraph-0">
					<span>
						·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
					</span>
					<a href="http://global.wf.com/inventory-finance-us-privacy-notice/">
						<span>
							<FormattedMessage id="security.messages-us-ocraEMEA" />
						</span>
					</a>
				</p>

				<p data-wf-efmya-ocra-id="securityEmeaSectionParagraph-1">
					<span>
						·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
					</span>
					<span>
						<FormattedMessage id="security.messages-outus-ocraEMEA" />
					</span>
					<a href="https://www.wellsfargo.com/privacy-security/privacy">
						<span>
							<FormattedMessage id="security.messages-wflink-ocraEMEA" />
						</span>
					</a>
					<span>
						<FormattedMessage id="security.messages-page-ocraEMEA" />
					</span>
				</p>
				<br />
				<p>
					<em>
						<FormattedMessage id="security.messages-protect-examples-ocraEMEA" />
					</em>
				</p>
				<ol>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-protect-encryption-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-protect-encryption-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-protect-firewalls-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-protect-firewalls-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-protect-timeout-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-protect-timeout-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-protect-registration-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-protect-registration-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-protect-recovery-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-protect-recovery-message-ocraEMEA" />
						</p>
					</li>
				</ol>

				<h2>
					<FormattedMessage id="security.messages.header-login-ocraEMEA" />
				</h2>
				<p>
					<FormattedMessage id="security.messages.header-login-message-ocraEMEA" />
				</p>

				<p>
					<em>
						<FormattedMessage id="security.messages.header-login-message-2-ocraEMEA" />
					</em>
				</p>
				<p></p>
				<ol>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-password-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-password-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-unique-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-unique-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-store-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-store-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-avoid-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-avoid-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-cache-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-cache-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-logout-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-logout-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-windows-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-windows-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-login-wireless-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-login-wireless-message-ocraEMEA" />
						</p>
					</li>
				</ol>

				<h2>
					<FormattedMessage id="security.messages.header-general-ocraEMEA" />
				</h2>
				<p>
					<FormattedMessage id="security.messages.header-general-message-ocraEMEA" />
				</p>

				<ol>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-general-business-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-general-business-message-ocraEMEA" />
						</p>
						<ol>
							<li>
								<strong>
									<FormattedMessage id="security.messages-general-monitor-ocraEMEA" />
								</strong>
								<FormattedMessage id="security.messages-general-monitor-message-ocraEMEA" />
							</li>
							<p></p>
						</ol>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-general-share-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-general-share-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-general-software-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-general-software-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-general-emails-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-general-emails-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-general-downloading-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-general-downloading-message-ocraEMEA" />
						</p>
					</li>
					<li>
						<p>
							<strong>
								<FormattedMessage id="security.messages-general-updated-ocraEMEA" />
							</strong>
							<FormattedMessage id="security.messages-general-updated-message-ocraEMEA" />
						</p>
					</li>
				</ol>
			</div>
		)
	}
	return null
}
