export const SHOW_SIGN_ON_ERROR_MESSAGE = 'SHOW_SIGN_ON_ERROR_MESSAGE'

export function showSignOnErrorMessage(signOnError) {
	return {
		type: SHOW_SIGN_ON_ERROR_MESSAGE,
		signOnError
	}
}

export const SHOW_REGISTRATION_ERROR_MESSAGE = 'SHOW_REGISTRATION_ERROR_MESSAGE'

export function showRegistrationErrorMessage(registrationError) {
	return {
		type: SHOW_REGISTRATION_ERROR_MESSAGE,
		registrationError
	}
}

export const SHOW_GENERAL_MESSAGE = 'SHOW_GENERAL_MESSAGE'

export function showGeneralMessage(messageId, iconKind) {
	return {
		type: SHOW_GENERAL_MESSAGE,
		messageId,
		iconKind
	}
}

export const SHOW_GENERAL_ERROR_MESSAGE = 'SHOW_GENERAL_ERROR_MESSAGE'

export function showGeneralErrorMessage(generalErrorMessage) {
	return {
		type: SHOW_GENERAL_ERROR_MESSAGE,
		generalErrorMessage
	}
}

export const SHOW_CREATE_USER_ID_ERROR_MESSAGE =
	'SHOW_CREATE_USER_ID_ERROR_MESSAGE'

export function showCreateUserIdErrorMessage(createUserIdError) {
	return {
		type: SHOW_CREATE_USER_ID_ERROR_MESSAGE,
		createUserIdError
	}
}

export const SHOW_FORGOT_USER_ID_MESSAGE = 'SHOW_FORGOT_USER_ID_MESSAGE'

export function showForgotUserIdMessage(forgotUserIdError) {
	return {
		type: SHOW_FORGOT_USER_ID_MESSAGE,
		forgotUserIdError
	}
}

export const SHOW_OUTAGE_MESSAGE = 'SHOW_OUTAGE_MESSAGE'

export function showOutageMessage(outageMessageText) {
	return {
		type: SHOW_OUTAGE_MESSAGE,
		outageMessageText
	}
}

export const SHOW_FEEDBACK_MESSAGE = 'SHOW_FEEDBACK_MESSAGE'

export function showFeedbackMessage(feedbackMessage) {
	return {
		type: SHOW_FEEDBACK_MESSAGE,
		feedbackMessage
	}
}

export const HIDE_ALL_MESSAGES = 'HIDE_ALL_MESSAGES'

export function hideAllMessages() {
	return {
		type: HIDE_ALL_MESSAGES
	}
}

export const HIDE_SIGNON_MESSAGES = 'HIDE_SIGNON_MESSAGES'

export function hideSignOnMessages() {
	return {
		type: HIDE_SIGNON_MESSAGES
	}
}

export const HIDE_REGISTRATION_MESSAGES = 'HIDE_REGISTRATION_MESSAGES'

export function hideRegistrationMessages() {
	return {
		type: HIDE_REGISTRATION_MESSAGES
	}
}
