import React from 'react'
import WcaForm from '../containers/WcaForm'
import ForgotUserIdForm from '../containers/ForgotUserIdForm'
import DeliveryMode from '../containers/DeliveryMode'
import CreateUserIdForm from '../containers/CreateUserIdForm'
import FeedbackForm from '../containers/FeedbackForm'
import SecureAccountForm from '../containers/SecureAccountForm'

export function WCA({ onCustomTextInputValidator }) {
	return (
		<div role="main">
			<WcaForm onCustomTextInputValidator={onCustomTextInputValidator} />
			<ForgotUserIdForm
				onCustomTextInputValidator={onCustomTextInputValidator}
			/>
			<DeliveryMode />
			<CreateUserIdForm
				onCustomTextInputValidator={onCustomTextInputValidator}
			/>
			<FeedbackForm onCustomTextInputValidator={onCustomTextInputValidator} />
			<SecureAccountForm
				onCustomTextInputValidator={onCustomTextInputValidator}
			/>
		</div>
	)
}
