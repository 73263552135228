import { connect } from 'react-redux'
import { Security } from '../components/Security'
import { injectIntl } from 'react-intl'
export const mapStateToProps = state => {
	return {
		mode: state.loginWidget.mode
	}
}

export default injectIntl(connect(mapStateToProps)(Security))
