import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { FeedbackForm } from '../components/FeedbackForm'
import {
	updateInput,
	submitFeedback,
	hideFeedback,
	updateAllowContactRegardingFeedback
} from '../actions/login'
import { isValidField } from '../isValidField'

export const mapStateToProps = state => {
	const {
		inputFields: { feedbackText, feedbackEmail },
		showFeedbackForm,
		allowContactRegardingFeedback
	} = state.loginWidget
	const { feedbackMessage } = state.messages
	return {
		feedbackText,
		feedbackEmail,
		charactersRemaining: 1000 - feedbackText.value.length,
		showFeedbackForm,
		showFeedbackSubmitButton: setShowFeedbackSubmitButton(
			feedbackText,
			feedbackEmail,
			allowContactRegardingFeedback
		),
		allowContactRegardingFeedback,
		feedbackMessage
	}
}

export function setShowFeedbackSubmitButton(
	feedbackText,
	feedbackEmail,
	allowContactRegardingFeedback
) {
	const feedbackEmailIsValid = validateFeedbackEmail(
		feedbackEmail.value,
		allowContactRegardingFeedback
	)
	const feedbackTextNotEmpty = feedbackText.value.length > 0
	return feedbackEmailIsValid && feedbackTextNotEmpty
}

export function validateFeedbackEmail(
	feedbackEmail,
	allowContactRegardingFeedback
) {
	if (allowContactRegardingFeedback === true) {
		return isValidField('feedbackEmail', feedbackEmail)
	}
	return true
}

export const mapDispatchToProps = dispatch => {
	return {
		onUpdateAllowContactRegardingFeedback(allowContactRegardingFeedback) {
			dispatch(
				updateAllowContactRegardingFeedback(allowContactRegardingFeedback)
			)
		},
		onSubmitFeedback() {
			dispatch(submitFeedback())
		},
		onHideFeedback() {
			dispatch(hideFeedback())
		},
		onUpdateInput(elementId, inputValue) {
			dispatch(updateInput(elementId, inputValue))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(FeedbackForm)
)
