import { POST } from 'ecpfs-authority'

import { RICOH, WFINFOLEASE, PLINFOLEASE } from '../businessSegmentBrandIds'

export const CC_RFI_ONBOARDING_LENDMGMT_USERS_PATH =
	'commercialLending/forgotUserId.json'
export const INFOLEASE_USERS_PATH = 'lease/forgotUserId.json'
export const PRIVATE_LABEL_USERS_PATH = 'lease/forgotUserId.json'
export const RICOH_USERS_PATH = 'ricoh/register/forgotUserIdRicoh.json'

export function fetchUserId(email, mode) {
	const PATH = getUserIdByBrandId(mode)
	return POST(PATH, email)
}

export function getUserIdByBrandId(mode) {
	switch (mode) {
		case RICOH:
			return RICOH_USERS_PATH
		case WFINFOLEASE:
			return INFOLEASE_USERS_PATH
		case PLINFOLEASE:
			return PRIVATE_LABEL_USERS_PATH
		default:
			return CC_RFI_ONBOARDING_LENDMGMT_USERS_PATH
	}
}
