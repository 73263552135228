import React from 'react'
import { Panel } from '@wf/ria'

export function ModalDialog({
	className,
	children,
	footer = null,
	headerDock = null,
	title,
	onClose,
	height = null,
	width = null,
	open,
	elementToFocusOnClose
}) {
	if (!!elementToFocusOnClose) {
		return (
			<Panel
				className={className}
				onClose={onClose}
				open={open}
				title={title}
				footer={footer}
				headerDock={headerDock}
				height={height}
				width={width}
				elementToFocusOnClose={elementToFocusOnClose}
			>
				{children}
			</Panel>
		)
	}
	return (
		<Panel
			className={className}
			onClose={onClose}
			open={open}
			title={title}
			footer={footer}
			headerDock={headerDock}
			height={height}
			width={width}
			trigger={<button style={{ display: 'none' }} />}
		>
			{children}
		</Panel>
	)
}
