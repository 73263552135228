import { validateField } from '../isValidField'
import {
	SET_COMPONENT_DETAILS,
	SET_COMPONENT_DETAILS_OUTAGE,
	INITIALIZE_WCA,
	SHOW_LOGIN,
	SET_IS_VALID_NICKNAME,
	CLEAR_SIGNON_FIELDS,
	CLEAR_REGISTRATION_FIELDS,
	UPDATE_INPUT,
	FOCUS_INPUT,
	SHOW_FORGOT_USER_ID,
	HIDE_FORGOT_USER_ID,
	GET_REGISTRATION_DETAILS_SUCCESS,
	CLOSE_FORGOT_USER_ID_RESPONSE,
	SHOW_CREATE_USER_ID,
	UPDATE_SHOW_LOADING,
	SUBMIT_NEW_USER_ID_SUCCESS,
	HIDE_CREATE_USER_ID,
	HIDE_DELIVERY_MODE,
	UPDATE_DELIVERY_MODE,
	SET_ONLINE_HELP_URL,
	SELECT_CUSTOMER_OR_CONTRACT,
	SELECT_CONTRACT_OR_INVOICE,
	SELECT_INVOICE_OR_SERIAL,
	SHOW_FEEDBACK,
	HIDE_FEEDBACK,
	SET_DEVICE_TYPE,
	HIDE_SECURE_ACCOUNT,
	UPDATE_EXISTING_CONTRACTS,
	UPDATE_NEWLY_ADDED_CONTRACTS,
	SET_CONTRACTS,
	SET_MODE_OF_ADDING_CONTRACTS,
	SUBMIT_CONTRACTS_SUCCESS,
	DISABLE_SECURE_SUBMIT_BUTTON,
	ENABLE_SECURE_SUBMIT_BUTTON,
	UPDATE_ALLOW_CONTACT_REGARDING_FEEDBACK,
	CLEAR_INVOICE_OR_SERIAL,
	CLEAR_CUSTOMER_OR_CONTRACT,
	CLEAR_CONTRACT_OR_INVOICE,
	SET_HIDE_FIELD_ERRORS,
	UPDATE_LANGUAGE_PREFERENCE,
	UPDATE_ESIGN_CONSENT
} from '../actions/login'
import { SHOW_CREATE_USER_ID_ERROR_MESSAGE } from '../actions/messages'
import {
	HIDE_TERMS_AND_POLICIES,
	DECLINE_TERMS_AND_POLICIES_SUCCESS,
	HIDE_ESIGN_CONSENT,
	DECLINE_E_SIGN_CONTENT
} from '../actions/termsAndPolicies'

export const defaultState = {
	get mode() {
		return ''
	},
	get deliveryMode() {
		return ''
	},
	get componentDetails() {
		return {
			bob: {
				scriptSrc: '',
				iframeSrc: ''
			},
			wca: {
				scriptSrc: ''
			},
			productName: '',
			mode: ''
		}
	},
	get componentDetailsOutage() {
		return false
	},
	get error() {
		return ''
	},
	get focusedElement() {
		return ''
	},
	get inputFields() {
		return {
			get email() {
				return {
					value: '',
					errorIds: []
				}
			},
			get existingEmail() {
				return {
					value: '',
					errorIds: []
				}
			},
			get emailForgotUser() {
				return {
					value: '',
					errorIds: []
				}
			},
			get dob() {
				return {
					value: '',
					errorIds: []
				}
			},
			get feedbackEmail() {
				return {
					value: '',
					errorIds: []
				}
			},
			get feedbackText() {
				return {
					value: '',
					errorIds: []
				}
			},
			get customerNumber() {
				return {
					value: '',
					errorIds: []
				}
			},
			get customerNumberRicoh() {
				return {
					value: '',
					errorIds: []
				}
			},
			get customerNumberRicohForgotUserId() {
				return {
					value: '',
					errorIds: []
				}
			},
			get contractNumber() {
				return {
					value: '',
					errorIds: []
				}
			},
			get contractNumberRicoh() {
				return {
					value: '',
					errorIds: []
				}
			},
			get contractNumberRicohForgotUserId() {
				return {
					value: '',
					errorIds: []
				}
			},
			get invoiceNumber() {
				return {
					value: '',
					errorIds: []
				}
			},
			get invoiceNumberRicohForgotUserId() {
				return {
					value: '',
					errorIds: []
				}
			},
			get serialNumber() {
				return {
					value: '',
					errorIds: []
				}
			},
			get password() {
				return {
					value: '',
					errorIds: []
				}
			},
			get loginUserId() {
				return {
					value: '',
					errorIds: []
				}
			},
			get newUserId() {
				return {
					value: '',
					errorIds: []
				}
			},

			get newUserIdNotCC() {
				return {
					value: '',
					errorIds: []
				}
			},

			get firstName() {
				return {
					value: '',
					errorIds: []
				}
			},
			get middleInitial() {
				return {
					value: '',
					errorIds: []
				}
			},
			get lastName() {
				return {
					value: '',
					errorIds: []
				}
			},
			get title() {
				return {
					value: '',
					errorIds: []
				}
			},
			get company() {
				return {
					value: '',
					errorIds: []
				}
			},
			get phone() {
				return {
					value: '',
					errorIds: []
				}
			},
			get extension() {
				return {
					value: '',
					errorIds: []
				}
			}
		}
	},
	get deviceType() {
		return 'landline'
	},
	get maskedUserDetails() {
		return {
			get maskedEmail() {
				return ''
			},
			get maskedPhone() {
				return ''
			}
		}
	},
	get message() {
		return ''
	},
	get showEsign() {
		return false
	},
	get showLoading() {
		return false
	},
	get sbaDetails() {
		return {
			get firstName() {
				return ''
			},
			get lastName() {
				return ''
			},
			get company() {
				return ''
			}
		}
	},
	get outageMessageText() {
		return ''
	},
	get showCreateUserIdResponse() {
		return false
	},
	get showDeliveryMode() {
		return false
	},
	get showEmailConfirmationResponse() {
		return false
	},
	get showForgotUserIdForm() {
		return false
	},
	get showForgotUserIdResponse() {
		return false
	},
	get onlineHelpUrl() {
		return ''
	},
	get showTextDeliveryMode() {
		return false
	},
	get showEmailDeliveryMode() {
		return false
	},
	get showUserIdConfirmationResponse() {
		return false
	},
	get showWcaForm() {
		return true
	},
	get widget() {
		return null
	},
	get customerOrContract() {
		return ''
	},
	get contractOrInvoice() {
		return ''
	},
	get invoiceOrSerial() {
		return ''
	},
	get showFeedbackForm() {
		return false
	},
	get showSecureAccount() {
		return false
	},
	get contractsInInitialForm() {
		return []
	},
	get existingContracts() {
		return []
	},
	get newContracts() {
		return []
	},
	get newlyAddedContracts() {
		return []
	},
	get secureAccountSubmitted() {
		return false
	},
	get secureAccountSubmitButtonDisabled() {
		return false
	},
	get modeOfAddingContracts() {
		return ''
	},
	get isValidNickname() {
		return true
	},
	get allowContactRegardingFeedback() {
		return null
	},
	get hideFieldErrors() {
		return {
			get loginUserId() {
				return false
			},
			get password() {
				return false
			},
			get email() {
				return false
			}
		}
	},
	get languagePreference() {
		return 'English'
	}
}

export function getErrorIds(elementId, inputValue) {
	return validateField(elementId, inputValue).map(messageId => {
		return {
			messageId,
			iconKind: 'error',
			show: true,
			className: 'UserIdError'
		}
	})
}

export function loginWidget(state = defaultState, action) {
	switch (action.type) {
		case SET_COMPONENT_DETAILS:
			return {
				...state,
				componentDetails: action.componentDetails,
				productName: action.componentDetails.productName,
				mode: action.componentDetails.mode
			}

		case SET_COMPONENT_DETAILS_OUTAGE:
			return {
				...state,
				componentDetailsOutage: true
			}

		case SET_ONLINE_HELP_URL:
			return {
				...state,
				onlineHelpUrl: action.onlineHelpUrl
			}

		case INITIALIZE_WCA:
			return { ...state, wcaWidget: action.wcaWidget }

		case SHOW_LOGIN:
			return { ...state, widget: action.widget }

		case CLEAR_SIGNON_FIELDS:
			return {
				...state,
				inputFields: {
					...state.inputFields,
					loginUserId: {
						value: '',
						errorIds: []
					},
					password: {
						value: '',
						errorIds: []
					}
				}
			}

		case CLEAR_REGISTRATION_FIELDS:
			return {
				...state,
				customerOrContract: defaultState.customerOrContract,
				contractOrInvoice: defaultState.contractOrInvoice,
				invoiceOrSerial: defaultState.invoiceOrSerial,
				inputFields: {
					...state.inputFields,
					email: {
						value: '',
						errorIds: []
					},
					existingEmail: {
						value: '',
						errorIds: []
					},
					dob: {
						value: '',
						errorIds: []
					},
					customerNumber: {
						value: '',
						errorIds: []
					},
					customerNumberRicoh: {
						value: '',
						errorIds: []
					},
					customerNumberRicohForgotUserId: {
						value: '',
						errorIds: []
					},
					contractNumber: {
						value: '',
						errorIds: []
					},
					contractNumberRicoh: {
						value: '',
						errorIds: []
					},
					contractNumberRicohForgotUserId: {
						value: '',
						errorIds: []
					},
					invoiceNumber: {
						value: '',
						errorIds: []
					},
					invoiceNumberRicohForgotUserId: {
						value: '',
						errorIds: []
					},
					serialNumber: {
						value: '',
						errorIds: []
					}
				}
			}

		case UPDATE_INPUT:
			return {
				...state,
				inputFields: {
					...state.inputFields,
					[action.elementId]: {
						...state.inputFields[action.elementId],
						value: action.inputValue,
						errorIds: getErrorIds(action.elementId, action.inputValue)
					}
				}
			}

		case FOCUS_INPUT:
			return {
				...state,
				focusedElement: action.focusedElement
			}

		case SHOW_CREATE_USER_ID_ERROR_MESSAGE:
			return {
				...state,
				inputFields: {
					...state.inputFields,
					newUserId: {
						...state.inputFields.newUserId,
						errorIds: [
							{
								messageId: action.createUserIdError,
								iconKind: 'error',
								show: true,
								className: 'UserIdError'
							}
						]
					}
				}
			}

		case SHOW_FORGOT_USER_ID:
			return {
				...state,
				showForgotUserIdForm: true,
				inputFields: defaultState.inputFields
			}

		case HIDE_FORGOT_USER_ID:
			return {
				...state,
				showForgotUserIdForm: false,
				inputFields: defaultState.inputFields
			}

		case GET_REGISTRATION_DETAILS_SUCCESS:
			return {
				...state,
				sbaDetails: action.sbaDetails,
				customerOrContract: defaultState.customerOrContract,
				invoiceOrSerial: defaultState.invoiceOrSerial
			}

		case CLOSE_FORGOT_USER_ID_RESPONSE:
			return {
				...state,
				showForgotUserIdResponse: false
			}

		case SHOW_CREATE_USER_ID:
			return {
				...state,
				showCreateUserIdForm: true
			}
		case UPDATE_SHOW_LOADING:
			return {
				...state,
				showLoading: action.showLoading
			}
		case HIDE_TERMS_AND_POLICIES:
		case HIDE_ESIGN_CONSENT:
		case DECLINE_TERMS_AND_POLICIES_SUCCESS:
		case DECLINE_E_SIGN_CONTENT:
			return {
				...state,
				inputFields: defaultState.inputFields,
				customerOrContract: defaultState.customerOrContract,
				contractOrInvoice: defaultState.contractOrInvoice,
				invoiceOrSerial: defaultState.invoiceOrSerial
			}
		case HIDE_CREATE_USER_ID:
			return {
				...state,
				showCreateUserIdForm: false,
				inputFields: defaultState.inputFields,
				customerOrContract: defaultState.customerOrContract,
				contractOrInvoice: defaultState.contractOrInvoice,
				invoiceOrSerial: defaultState.invoiceOrSerial
			}

		case SHOW_FEEDBACK:
			return {
				...state,
				showFeedbackForm: true
			}

		case HIDE_FEEDBACK:
			return {
				...state,
				showFeedbackForm: false,
				inputFields: {
					...state.inputFields,
					feedbackEmail: defaultState.inputFields.feedbackEmail,
					feedbackText: defaultState.inputFields.feedbackText
				},
				allowContactRegardingFeedback:
					defaultState.allowContactRegardingFeedback
			}
		case SUBMIT_NEW_USER_ID_SUCCESS:
			return {
				...state,
				inputFields: {
					...state.inputFields,
					password: {
						...state.inputFields.password
					}
				},
				maskedUserDetails: action.maskedUserDetails,
				message: action.message,
				showCreateUserIdForm: false,
				showDeliveryMode: true,
				showTextDeliveryMode: action.showTextDeliveryMode,
				showEmailDeliveryMode: action.showEmailDeliveryMode
			}

		case HIDE_DELIVERY_MODE:
			return {
				...state,
				showDeliveryMode: false
			}

		case UPDATE_DELIVERY_MODE:
			return {
				...state,
				deliveryMode: action.deliveryMode
			}

		case SELECT_CUSTOMER_OR_CONTRACT:
			return {
				...state,
				customerOrContract: action.customerOrContract
			}

		case SELECT_CONTRACT_OR_INVOICE:
			return {
				...state,
				contractOrInvoice: action.contractOrInvoice
			}

		case SELECT_INVOICE_OR_SERIAL:
			return {
				...state,
				invoiceOrSerial: action.invoiceOrSerial
			}
		case CLEAR_CUSTOMER_OR_CONTRACT:
			return {
				...state,
				inputFields: {
					...state.inputFields,
					contractNumber: {
						value: '',
						errorIds: []
					},
					customerNumber: {
						value: '',
						errorIds: []
					},
					customerNumberRicoh: {
						value: '',
						errorIds: []
					}
				}
			}

		case CLEAR_INVOICE_OR_SERIAL:
			return {
				...state,
				inputFields: {
					...state.inputFields,
					serialNumber: {
						value: '',
						errorIds: []
					},
					invoiceNumber: {
						value: '',
						errorIds: []
					}
				}
			}

		case CLEAR_CONTRACT_OR_INVOICE:
			return {
				...state,
				inputFields: {
					...state.inputFields,
					contractNumber: {
						value: '',
						errorIds: []
					},
					invoiceNumber: {
						value: '',
						errorIds: []
					},
					contractNumberRicoh: {
						value: '',
						errorIds: []
					},
					contractNumberRicohForgotUserId: {
						value: '',
						errorIds: []
					},
					invoiceNumberRicohForgotUserId: {
						value: '',
						errorIds: []
					}
				}
			}

		case SET_DEVICE_TYPE:
			return {
				...state,
				deviceType: action.deviceType
			}

		case HIDE_SECURE_ACCOUNT:
			return {
				...state,
				showSecureAccount: false
			}

		case UPDATE_EXISTING_CONTRACTS:
			return {
				...state,
				existingContracts: action.existingContracts
			}

		case UPDATE_NEWLY_ADDED_CONTRACTS:
			return {
				...state,
				newlyAddedContracts: action.newlyAddedContracts
			}

		case SET_CONTRACTS:
			return {
				...state,
				...action.contracts,
				contractsInInitialForm: action.contractsInInitialForm,
				showSecureAccount: true
			}

		case SET_MODE_OF_ADDING_CONTRACTS:
			return {
				...state,
				modeOfAddingContracts: action.modeOfAddingContracts
			}
		case SET_IS_VALID_NICKNAME:
			return {
				...state,
				isValidNickname: action.isValidNickname
			}

		case SUBMIT_CONTRACTS_SUCCESS:
			return {
				...state,
				secureAccountSubmitted: true,
				showSecureAccount: false
			}
		case DISABLE_SECURE_SUBMIT_BUTTON:
			return {
				...state,
				secureAccountSubmitButtonDisabled: true
			}
		case ENABLE_SECURE_SUBMIT_BUTTON:
			return {
				...state,
				secureAccountSubmitButtonDisabled: false
			}
		case UPDATE_ALLOW_CONTACT_REGARDING_FEEDBACK:
			return {
				...state,
				allowContactRegardingFeedback: action.allowContactRegardingFeedback,
				inputFields: {
					...state.inputFields,
					feedbackEmail: defaultState.inputFields.feedbackEmail
				}
			}

		case SET_HIDE_FIELD_ERRORS:
			return {
				...state,
				hideFieldErrors: {
					...state.hideFieldErrors,
					...action.hideFieldErrors
				}
			}

		case UPDATE_LANGUAGE_PREFERENCE:
			return {
				...state,
				languagePreference: action.languagePreference
			}
		case UPDATE_ESIGN_CONSENT:
			return {
				...state,
				showEsign: action.showEsign
			}
		default:
			return state
	}
}
