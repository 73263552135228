import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FeedbackForm } from './FeedbackForm'
import {
	RICOH,
	WFINFOLEASE,
	PLINFOLEASE,
	SBA,
	EASYCONNECT
} from '../businessSegmentBrandIds'
import { getTextInputValidationMessage } from '../isValidField'

export function onCustomTextInputValidator(
	elementId,
	inputValue,
	formatMessage
) {
	return getTextInputValidationMessage(elementId, inputValue, formatMessage)
}
export function ForgotUserIdSpan3({ idSuffix }) {
	if (idSuffix === '-sba') {
		return <FormattedMessage id="faqs.messages.answer.forgot-user-id.3-sba" />
	}
	return <FormattedMessage id="faqs.messages.answer.forgot-user-id.3" />
}
export function BrowserRequirementTable() {
	return (
		<table data-wf-efmya-id="browserRequirementTable">
			<tbody>
				<tr>
					<th>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-os" />
					</th>
					<th>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-browsers" />
					</th>
				</tr>
				<tr>
					<td>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-os.2" />
					</td>
					<td>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-browsers.2" />
					</td>
				</tr>
				<tr>
					<td>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-os.5" />
					</td>
					<td>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-browsers.5" />
					</td>
				</tr>
				<tr>
					<td>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-os.6" />
					</td>
					<td>
						<FormattedMessage id="faqs.messages.answer.browser-requirements.table.supported-browsers.6" />
					</td>
				</tr>
			</tbody>
		</table>
	)
}
export function AnswerContactSupport({ mode }) {
	const idSuffix = getIdSuffixByMode(mode)
	const isOCRA = RegExp(/ocra/)
	if (mode.match(isOCRA)) {
		return (
			<div className="Answer wf-text7">
				<FormattedMessage id={'faqs.messages.answer.contact-support-ocra'} />
			</div>
		)
	}
	if (
		idSuffix === '-infolease' ||
		idSuffix === '-ricoh' ||
		idSuffix === '-sba'
	) {
		return (
			<div className="Answer wf-text7">
				<FormattedMessage
					id={'faqs.messages.answer.contact-support' + idSuffix}
				/>
				<strong>
					<FormattedMessage id={'faqs.messages.answer.contact-support.1'} />
				</strong>
				<FormattedMessage id={'faqs.messages.answer.contact-support.2'} />
			</div>
		)
	}
	return (
		<div className="Answer wf-text7">
			<FormattedMessage
				id={'faqs.messages.answer.contact-support' + idSuffix}
			/>
		</div>
	)
}
export function OcraTable({ mode }) {
	return (
		<div className="Faqs">
			<h1 className="wf-header-secondary wf-text6">
				<strong>
					<FormattedMessage id="faqs.messages.header.frequently-asked" />
				</strong>
			</h1>
			<div data-wf-efmya-id="questions">
				<ul className="wf-list-unstyled">
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.contact-support" />
						</h2>
						<AnswerContactSupport mode={mode} />
						<div className="Answer wf-text7">
							<table
								data-wf-efmya-ocra-id="browserRequirementTable"
								cellSpacing="0"
							>
								<tbody>
									<tr>
										<th>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-th.1" />
										</th>
										<th>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-th.2" />
										</th>
										<th>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-th.3" />
										</th>
									</tr>
									<tr>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-country.1" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-contact.1" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-hours.1" />
										</td>
									</tr>
									<tr>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-country.2" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-contact.2" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-hours.2" />
										</td>
									</tr>
									<tr>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-country.3" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-contact.3" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-hours.3" />
										</td>
									</tr>
									<tr>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-country.4" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-contact.4" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-hours.4" />
										</td>
									</tr>
									<tr>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-country.5" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-contact.5" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-hours.5" />
										</td>
									</tr>
									<tr>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-country.6" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-contact.6" />
										</td>
										<td>
											<FormattedMessage id="faqs.messages.answer.contact-support-ocra-hours.6" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.login-ocra" />
						</h2>
						<div className="Answer wf-text7">
							<ul className="wf-list-unstyled">
								<li className="wf-text7">
									<FormattedMessage id="faqs.messages.answer.access-ocra" />
									<ul>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.login.reason-ocra" />
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.can't.log-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.can't.log-ocra.1" />
						</div>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.can't.log-ocra.2" />
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.forgot.userid-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.forgot.userid-ocra" />
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.change.password-ocra" />
						</h2>
						<div className="Answer wf-text7">
							<ul className="wf-list-unstyled">
								<li className="wf-text7">
									<ul>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.change.password-ocra.1" />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.change.password-ocra.2" />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.change.password-ocra.3" />
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.password.expire-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.password.expire-ocra.1" />
						</div>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.password.expire-ocra.2" />
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.phone-required-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.phone-required-ocra" />
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.secure.validation-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.secure.validation-ocra" />
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.complete.secure.validation-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<ul className="wf-list-unstyled">
								<li className="wf-text7">
									<FormattedMessage id="faqs.messages.answer.complete.secure.validation-ocra" />
									<ul>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete.secure.validation.step.1-ocra" />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete.secure.validation.step.2-ocra" />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete.secure.validation.step.3-ocra" />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete.secure.validation.step.4-ocra" />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete.secure.validation.step.5-ocra" />
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.code.expired-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.code.expired-ocra" />
						</div>
					</li>
					<li>
						<h2 className="Question wf-text6">
							<FormattedMessage id="faqs.messages.question.browser.requirements-ocra" />
						</h2>
						<div className="Answer wf-text7 ">
							<FormattedMessage id="faqs.messages.answer.browser.requirements-ocra" />
							<BrowserRequirementTable />
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}
export function FooterText({ mode }) {
	if (mode === EASYCONNECT) {
		return (
			<div className="wf-col--12 FooterWrapper">
				<div className="wf-col--mob-only-12 wf-col--tab-12 wf-col--desk-10 WrapperText wf-text7">
					<h2 className="InvestmentText">
						<FormattedMessage id="footer.investment-and-insurance-products.label" />
					</h2>
					<ul className="wf-list-styled">
						<li className="wf-text7">
							<FormattedMessage id="footer.investment.not-insured.reason.1" />
						</li>
						<li className="wf-text7">
							<FormattedMessage id="footer.investment.not-deposit.reason.2" />
						</li>
						<li className="wf-text7">
							<FormattedMessage id="footer.investment.subject-to-investment-risks.reason.3" />
						</li>
					</ul>
				</div>
				<div className="DisclosureText">
					<span>
						<FormattedMessage id="footer.deposit.products-disclosure" />
					</span>
				</div>
			</div>
		)
	}
	return null
}

export function Faqs({
	mode = '',
	showTermsAndPolicies,
	feedbackEmail,
	feedbackText,
	charactersRemaining,
	allowContactRegardingFeedback,
	showFeedbackForm,
	showFeedbackSubmitButton,
	feedbackMessage,
	onUpdateInput,
	onUpdateAllowContactRegardingFeedback,
	onSubmitFeedback,
	onHideFeedback,
	intl
}) {
	const isOCRA = RegExp(/ocra/)
	const idSuffix = getIdSuffixByMode(mode)
	if (showTermsAndPolicies) {
		return null
	}
	if (mode.match(isOCRA)) {
		return <OcraTable mode={mode} />
	}
	return (
		<div className="wf-row">
			<div className="wf-col--desk-lg-12 wf-col--desk-10 wf-col--mob-only-10">
				<FeedbackForm
					showFeedbackSubmitButton={showFeedbackSubmitButton}
					feedbackMessage={feedbackMessage}
					feedbackEmail={feedbackEmail}
					onSubmitFeedback={onSubmitFeedback}
					onUpdateInput={onUpdateInput}
					onUpdateAllowContactRegardingFeedback={
						onUpdateAllowContactRegardingFeedback
					}
					feedbackText={feedbackText}
					charactersRemaining={charactersRemaining}
					allowContactRegardingFeedback={allowContactRegardingFeedback}
					showFeedbackForm={showFeedbackForm}
					onCustomTextInputValidator={onCustomTextInputValidator}
					intl={intl}
					onHideFeedback={onHideFeedback}
				/>
				<div className="Faqs">
					<h1 className="wf-header-secondary wf-text6">
						<strong>
							<FormattedMessage id="faqs.messages.header.frequently-asked" />
						</strong>
					</h1>
					<div data-wf-efmya-id="Questions" className="Questions">
						<ul className="wf-list-unstyled">
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.contact-support" />
								</h2>
								<AnswerContactSupport mode={mode} />
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.browser-requirements" />
								</h2>
								<div className="Answer wf-text7">
									<FormattedMessage id="faqs.messages.answer.browser-requirements" />
									<BrowserRequirementTable />
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.can't-access-my-account" />
								</h2>
								<div className="Answer wf-text7">
									<ul className="wf-list-unstyled">
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.can't-access-my-account.if" />
											<ul>
												<li className="wf-text7">
													<FormattedMessage id="faqs.messages.answer.can't-access-my-account.reason.1" />
												</li>
												<li className="wf-text7">
													<FormattedMessage id="faqs.messages.answer.can't-access-my-account.reason.2" />
												</li>
											</ul>
										</li>
										<li className="wf-text7">
											<FormattedMessage
												id={
													"faqs.messages.answer.can't-access-my-account.support" +
													idSuffix
												}
											/>
										</li>
									</ul>
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.phone-required" />
								</h2>
								<div className="Answer wf-text7 ">
									<FormattedMessage id="faqs.messages.answer.phone-required" />
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.forgot-user-id" />
								</h2>
								<div className="Answer wf-text7">
									<FormattedMessage id="faqs.messages.answer.forgot-user-id.1" />
									<span>
										<strong>
											<FormattedMessage id="faqs.messages.answer.forgot-user-id.2" />
										</strong>
									</span>
									<span>
										<ForgotUserIdSpan3 idSuffix={idSuffix} />
									</span>
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.password-expired" />
								</h2>
								<div className="Answer wf-text7">
									<FormattedMessage id="faqs.messages.answer.password-expired.1" />
									<br />
									<br />
									<FormattedMessage
										id={'faqs.messages.answer.password-expired.2' + idSuffix}
									/>
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.secure-validation" />
								</h2>
								<div className=" Answer wf-text7">
									<FormattedMessage id="faqs.messages.answer.secure-validation" />
								</div>
							</li>

							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.validation-code-expired" />
								</h2>
								<div className="Answer wf-text7">
									<FormattedMessage
										id={
											'faqs.messages.answer.validation-code-expired' + idSuffix
										}
									/>
								</div>
							</li>
						</ul>
					</div>
					<h2 className="wf-header-secondary wf-text6">
						<strong>
							<FormattedMessage id="faqs.messages.header.how-do-i" />
						</strong>
					</h2>
					<div data-wf-efmya-id="Questions" className="Questions">
						<ul className="wf-list-unstyled">
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.change-email-phone-profile-info" />
								</h2>
								<div className="Answer wf-text7">
									<FormattedMessage
										id={
											'faqs.messages.answer.change-email-phone-profile-info' +
											idSuffix
										}
										defaultMessage="{defaultValue}"
										values={{
											defaultValue: (
												<FormattedMessage
													id={
														'faqs.messages.answer.change-email-phone-profile-info'
													}
												/>
											)
										}}
									/>
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.change-password" />
								</h2>
								<div className="Answer wf-text7">
									<ul className="wf-list-unstyled">
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.change-password.1" />
											<strong>
												<FormattedMessage id="faqs.messages.answer.change-password.1.forgot-password" />
											</strong>
											<br />
											<br />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.change-password.2" />
											<strong>
												<FormattedMessage id="faqs.messages.answer.change-password.2.note" />
											</strong>
											<FormattedMessage id="faqs.messages.answer.change-password.2.every-24-hours" />
											<br />
											<br />
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.change-password.3" />
											<br />
											<br />
										</li>
									</ul>
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.complete-validation" />
								</h2>
								<div className="Answer wf-text7">
									<FormattedMessage id="faqs.messages.answer.complete-validation" />
									<br />
									<br />
									<ul>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete-validation.step.1" />
										</li>
										<li className="wf-text7">
											<span>
												<FormattedMessage id="faqs.messages.answer.complete-validation.step.2" />
												<strong>
													<FormattedMessage id="faqs.messages.answer.complete-validation.step.2.send-code-now" />
												</strong>
											</span>
											<ul>
												<li className="wf-text7">
													<FormattedMessage id="faqs.messages.answer.complete-validation.step.2.option.1" />
												</li>
												<li className="wf-text7">
													<FormattedMessage
														id={
															'faqs.messages.answer.complete-validation.step.2.option.2' +
															idSuffix
														}
													/>
												</li>
											</ul>
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete-validation.step.3" />
											<ul>
												<li className="wf-text7">
													<FormattedMessage id="faqs.messages.answer.complete-validation.step.3.option.1" />
													<strong>
														<FormattedMessage id="faqs.messages.answer.complete-validation.step.3.option.1.validation-code-resent" />
													</strong>
												</li>
												<li className="wf-text7">
													<FormattedMessage id="faqs.messages.answer.complete-validation.step.3.option.2" />
												</li>
											</ul>
										</li>
										<li className="wf-text7">
											<FormattedMessage id="faqs.messages.answer.complete-validation.step.4" />
										</li>
									</ul>
								</div>
							</li>
							<li>
								<h2 className="Question wf-text6">
									<FormattedMessage id="faqs.messages.question.access-disabled-account" />
								</h2>
								<div className="Answer wf-text7">
									<FormattedMessage
										id={
											'faqs.messages.answer.access-disabled-account' + idSuffix
										}
									/>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<FooterText mode={mode} />
			</div>
		</div>
	)
}

export function getIdSuffixByMode(mode) {
	if (mode === 'wforms') {
		return '-wforms'
	}
	if (mode === 'docex') {
		return '-docex'
	}
	if (mode === 'rfi') {
		return '-rfi'
	}
	if (mode === WFINFOLEASE || mode === PLINFOLEASE) {
		return '-infolease'
	}
	if (mode === SBA || mode === EASYCONNECT) {
		return '-sba'
	}
	if (mode === RICOH) {
		return '-ricoh'
	}
	return ''
}
