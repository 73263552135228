import { VALIDATIONS } from './defaultInputValidations'

export function isValidField(field, value) {
	const invalidRules = validateField(field, value)
	return invalidRules.length === 0
}

export function validateField(field, value) {
	const rules = VALIDATIONS[field]
	const rulesMinMax = VALIDATIONS['minMaxTextInput'][field]
	const defaultCustomValidationRegex = validateRegex(rules, value)
	if (defaultCustomValidationRegex.length > 0) {
		return defaultCustomValidationRegex
	} else if (rulesMinMax) {
		return validateRegex(rulesMinMax, value)
	} else {
		return defaultCustomValidationRegex
	}
}

export function validateRegex(rules, value) {
	return Object.keys(rules).reduce((result, rule) => {
		const regex = new RegExp(rules[rule])
		if (!regex.test(value)) {
			result.push(rule)
		}
		return result
	}, [])
}

export function validateTextInputField(elementId, inputValue) {
	const getTextInputErrorMsgId = getTextInputFieldErrorMsgId(
		elementId,
		inputValue
	)
	if (getTextInputErrorMsgId.length > 0) {
		return getTextInputFieldErrorMsgId(elementId, inputValue).map(messageId => {
			const fieldLabelId = getTextInputFieldMessageId(elementId)
			if (fieldLabelId) {
				const msgIdFieldLabelId = {
					messageId: messageId,
					fieldLabelId: fieldLabelId
				}
				return msgIdFieldLabelId
			} else {
				const msgIdFieldLabelId = { messageId: messageId, fieldLabelId: null }
				return msgIdFieldLabelId
			}
		})[0]
	} else {
		return null
	}
}

export function getTextInputFieldErrorMsgId(elementId, inputValue) {
	const getMinMaxErrorMsgId = validateTextInputMinMax(elementId, inputValue)
	const rules = VALIDATIONS[elementId]
	const customRuleErrorMsgId = validateRegex(rules, inputValue)
	if (customRuleErrorMsgId.length > 0) {
		return customRuleErrorMsgId
	} else {
		return getMinMaxErrorMsgId
	}
}

export function validateTextInputMinMax(elementId, inputValue) {
	const minMaxTextInput = 'minMaxTextInput'
	switch (elementId) {
		case 'customerNumber':
		case 'customerNumberRicoh':
		case 'contractNumber':
		case 'contractNumberRicoh':
		case 'invoiceNumber':
		case 'serialNumber':
		case 'firstName':
		case 'middleInitial':
		case 'title':
		case 'company':
		case 'companyRicoh':
		case 'lastName':
		case 'newUserIdNotCC':
		case 'customerNumberRicohForgotUserId':
		case 'contractNumberRicohForgotUserId':
		case 'invoiceNumberRicohForgotUserId':
			const rules = VALIDATIONS[minMaxTextInput][elementId]
			return validateRegex(rules, inputValue)
		default:
			return []
	}
}

export function getTextInputFieldMessageId(elementId) {
	const rulesLabelId = VALIDATIONS['fieldLabelTextInput'][elementId]
	if (rulesLabelId) {
		return rulesLabelId['fieldLabelId']
	}
	return false
}

export function getTextInputValidationMessage(
	elementId,
	inputValue,
	formatMessage
) {
	const errorMessage = validateTextInputField(elementId, inputValue)
	if (errorMessage) {
		const { fieldLabelId, messageId } = errorMessage
		if (fieldLabelId) {
			const fieldLabel = formatMessage({ id: fieldLabelId })
			return formatMessage({ id: messageId }, { fieldLabel })
		} else {
			return formatMessage({ id: messageId })
		}
	}
	return null
}
