import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Button, RadioButton, TextInput } from '@wf/ria'
import { ModalDialog } from './ModalDialog'
import { SystemMessage } from './SystemMessages'
import '@wf/ria/lib/css/Flexgrid/Flexgrid.css'

const feedbackEmailRef = React.createRef()

export function FeedbackFormContent({
	feedbackEmail,
	feedbackText,
	charactersRemaining,
	allowContactRegardingFeedback,
	feedbackMessage,
	formatMessage,
	onUpdateInput,
	onUpdateAllowContactRegardingFeedback,
	onCustomTextInputValidator
}) {
	const defaultInputClasses =
		'wf-row api-xsmall-margin-bottom FeedbackFormEmail'
	const feedbackEmailHasViolations = feedbackEmail.errorIds.length > 0
	const feedbackEmailInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: feedbackEmailHasViolations
	})
	const valueMissing = formatMessage({ id: 'required-field.empty.error.label' })

	function onChange(event) {
		const { id, value } = event.target
		onUpdateInput(id, value)
	}

	function invokeOnUpdateAllowContactRegardingFeedback(event) {
		const { value } = event.target
		const allowContact = value === 'yes'
		if (!allowContact) {
			feedbackEmailRef.current.validate('reset')
		}
		onUpdateAllowContactRegardingFeedback(allowContact)
	}

	function onTextInputValidator(elementId, inputValue) {
		return onCustomTextInputValidator(elementId, inputValue, formatMessage)
	}

	return (
		<React.Fragment>
			<SystemMessage
				show={feedbackMessage.show}
				iconKind={feedbackMessage.iconKind}
				messageId={feedbackMessage.message}
			/>
			<p>
				<FormattedMessage id="feedback-form-content.feedback-information-1.text" />
			</p>
			<p>
				<FormattedMessage id="feedback-form-content.feedback-information-2.text" />
			</p>
			<textarea
				rows="7"
				id="feedbackText"
				maxLength="1000"
				value={feedbackText.value}
				onChange={onChange}
			/>
			<p className="FeedbackFormContentCharactersRemaining">
				<FormattedMessage
					id="feedback-form-content.characters-remaining.text"
					values={{ charactersRemaining }}
				/>
			</p>
			<div className="FeedbackFormQuestion">
				<span>
					<FormattedMessage id="feedback-form-content.feedback-question.text" />
				</span>
				<RadioButton
					label={formatMessage({
						id: 'feedback-form-content.feedback-question.yes.label'
					})}
					value={'yes'}
					name="yes"
					onChange={invokeOnUpdateAllowContactRegardingFeedback}
					checked={allowContactRegardingFeedback === true}
				/>
				<RadioButton
					label={formatMessage({
						id: 'feedback-form-content.feedback-question.no.label'
					})}
					value={'no'}
					name="no"
					onChange={invokeOnUpdateAllowContactRegardingFeedback}
					checked={allowContactRegardingFeedback === false}
				/>
			</div>
			<TextInput
				aria-label={formatMessage({
					id: 'feedback-form-content.feedback-email.aria-label'
				})}
				id="feedbackEmail"
				ref={feedbackEmailRef}
				name="feedbackEmail"
				label={formatMessage({
					id: 'feedback-form-content.feedback-email.label'
				})}
				className={feedbackEmailInputClasses}
				placeholder={formatMessage({
					id: 'feedback-form-content.feedback-email.placeholder'
				})}
				aria-required={true}
				fieldClassName="wf-col wf-col--desk-4 wf-col--tab-6 wf-col--mob-only-12"
				disabled={!allowContactRegardingFeedback}
				value={feedbackEmail.value}
				onChange={onChange}
				customValidators={[
					value => onTextInputValidator('feedbackEmail', value)
				]}
				nativeValidationMessages={{
					valueMissing
				}}
			/>
		</React.Fragment>
	)
}

export function FeedbackFormFooter({
	showFeedbackSubmitButton,
	onSubmitFeedback,
	onHideFeedback,
	formatMessage
}) {
	return (
		<div>
			<Button
				className="FeedbackFormFormFooterSubmitButton"
				label={formatMessage({
					id: 'feedback-form-footer.submit.button.label'
				})}
				kind="primary"
				disabled={!showFeedbackSubmitButton}
				onClick={onSubmitFeedback}
			/>
			<Button
				className="FeedbackFormFormFooterCancelButton"
				label={formatMessage({
					id: 'feedback-form-footer.cancel.button.label'
				})}
				kind="chromeless"
				onClick={onHideFeedback}
			/>
		</div>
	)
}

export function FeedbackForm({
	feedbackEmail,
	feedbackText,
	charactersRemaining,
	allowContactRegardingFeedback,
	showFeedbackForm,
	showFeedbackSubmitButton,
	feedbackMessage,
	onUpdateInput,
	onUpdateAllowContactRegardingFeedback,
	onSubmitFeedback,
	onHideFeedback,
	intl,
	onCustomTextInputValidator
}) {
	const { formatMessage } = intl

	return (
		<ModalDialog
			className="FeedbackForm"
			footer={
				<FeedbackFormFooter
					showFeedbackSubmitButton={showFeedbackSubmitButton}
					onSubmitFeedback={onSubmitFeedback}
					onHideFeedback={onHideFeedback}
					formatMessage={formatMessage}
				/>
			}
			onClose={onHideFeedback}
			open={showFeedbackForm}
			title={formatMessage({
				id: 'feedback-form.title.label'
			})}
			width="950px"
		>
			<FeedbackFormContent
				feedbackEmail={feedbackEmail}
				feedbackText={feedbackText}
				charactersRemaining={charactersRemaining}
				allowContactRegardingFeedback={allowContactRegardingFeedback}
				feedbackMessage={feedbackMessage}
				onUpdateInput={onUpdateInput}
				onUpdateAllowContactRegardingFeedback={
					onUpdateAllowContactRegardingFeedback
				}
				formatMessage={formatMessage}
				onCustomTextInputValidator={onCustomTextInputValidator}
			/>
		</ModalDialog>
	)
}
