import React from 'react'
import { Button, RadioButton } from '@wf/ria'
import { ModalDialog } from './ModalDialog'
import { FormattedMessage } from 'react-intl'
import '@wf/ria/lib/css/Flexgrid/Flexgrid.css'

export function DeliveryModeButton({
	show,
	id,
	buttonType,
	deliveryMode,
	formatMessage,
	onUpdateDeliveryMode,
	labelText
}) {
	function onChange(event) {
		const value = event.target.value
		onUpdateDeliveryMode(value)
	}
	if (show) {
		const deliveryType = formatMessage({
			id: `delivery-mode.label.button-type.${buttonType}`
		})
		return (
			<RadioButton
				id={id}
				name="email"
				value={buttonType}
				checked={deliveryMode === buttonType}
				onChange={event => onChange(event)}
				label={`${labelText} ${deliveryType}`}
			/>
		)
	}
	return null
}

export function DeliveryModeContent({
	deliveryMode,
	maskedUserDetails,
	showTextDeliveryMode,
	showEmailDeliveryMode,
	formatMessage,
	onUpdateDeliveryMode
}) {
	return (
		<div className="DeliveryModeContent">
			<span className="DeliveryModeContent_Header">
				<FormattedMessage
					id="delivery-mode.content-header.instruction"
					values={{
						continue: (
							<strong>
								<FormattedMessage id="delivery-mode.content-header.instruction.__continue" />
							</strong>
						)
					}}
				/>
			</span>
			<fieldset>
				<legend className="wf-u-visually-hidden">Delivery Mode</legend>
				<DeliveryModeButton
					id="DeliveryModeContent_Voice"
					show={true}
					buttonType="voice"
					deliveryMode={deliveryMode}
					formatMessage={formatMessage}
					onUpdateDeliveryMode={onUpdateDeliveryMode}
					labelText={maskedUserDetails.maskedPhone}
				/>
				<DeliveryModeButton
					id="DeliveryModeContent_Email"
					show={showEmailDeliveryMode}
					buttonType="email"
					deliveryMode={deliveryMode}
					formatMessage={formatMessage}
					onUpdateDeliveryMode={onUpdateDeliveryMode}
					labelText={maskedUserDetails.maskedEmail}
				/>
				<DeliveryModeButton
					id="DeliveryModeContent_Text"
					show={showTextDeliveryMode}
					buttonType="text"
					deliveryMode={deliveryMode}
					formatMessage={formatMessage}
					onUpdateDeliveryMode={onUpdateDeliveryMode}
					labelText={maskedUserDetails.maskedPhone}
				/>
			</fieldset>
			<span className="DeliveryModeContent_Disclaimer">
				<FormattedMessage id="delivery-mode.content.disclaimer" />
			</span>
		</div>
	)
}

export function DeliveryModeFooter({ deliveryMode, onSelectDeliveryMode }) {
	return (
		<div>
			<Button
				className="DeliveryModeFooterContinueButton"
				kind="primary"
				onClick={() => onSelectDeliveryMode()}
				disabled={!deliveryMode}
			>
				<FormattedMessage id="delivery-mode.delivery-mode-footer.button.label" />
			</Button>
		</div>
	)
}

export function DeliveryMode({
	deliveryMode,
	inputFields,
	maskedUserDetails,
	sbaDetails,
	showDeliveryMode,
	showTextDeliveryMode,
	showEmailDeliveryMode,
	onHideDeliveryMode,
	onSelectDeliveryMode,
	onUpdateDeliveryMode,
	intl
}) {
	const { formatMessage } = intl
	return (
		<ModalDialog
			className="DeliveryMode"
			footer={
				<DeliveryModeFooter
					deliveryMode={deliveryMode}
					inputFields={inputFields}
					onHideCreateUserId={onHideDeliveryMode}
					onSelectDeliveryMode={onSelectDeliveryMode}
				/>
			}
			width={'800px'}
			onClose={onHideDeliveryMode}
			open={showDeliveryMode}
			title={formatMessage({
				id: 'delivery-mode.title'
			})}
		>
			<DeliveryModeContent
				deliveryMode={deliveryMode}
				inputFields={inputFields}
				maskedUserDetails={maskedUserDetails}
				sbaDetails={sbaDetails}
				showTextDeliveryMode={showTextDeliveryMode}
				showEmailDeliveryMode={showEmailDeliveryMode}
				formatMessage={formatMessage}
				onUpdateDeliveryMode={onUpdateDeliveryMode}
			/>
		</ModalDialog>
	)
}
