import React from 'react'
import { Button } from '@wf/ria'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { ModalDialog } from './ModalDialog'
import { ForgotUserIdContractNumber } from './ForgotUserIdContractNumber'
import { ForgotUserIdContentInput } from './ForgotUserIdContentInput'
import { ForgotUserIdRicohContractNumber } from './ForgotUserIdRicohContractNumber'
import { onFocusUserIdInput } from './RegistrationForm'
import {
	DOCEX,
	WFORMS,
	RFI,
	ONBOARD,
	SBA,
	DLO,
	COBO,
	EASYCONNECT
} from '../businessSegmentBrandIds'

export function ForgotUserIdFormContent({
	emailForgotUser,
	dob,
	emailForgotUserRef,
	dobForgotUserRef,
	formatMessage,
	forgotUserIdMessage,
	onUpdateInput,
	onFocusInput,
	onCustomTextInputValidator,
	contractNumber,
	mode,
	customerNumberRicohForgotUserId,
	contractNumberRicohForgotUserId,
	invoiceNumberRicohForgotUserId,
	contractOrInvoice,
	focusedElement,
	onSelectContractOrInvoice
}) {
	const brandIdIsCCOrOnboardingOrLendMgmtOrRFI =
		mode === DOCEX ||
		mode === WFORMS ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === RFI ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	const defaultEmailInputClasses = 'textField'
	const emailHasViolation = emailForgotUser.errorIds.length > 0
	const emailInputClasses = classNames(defaultEmailInputClasses, {
		HasInputViolation: emailHasViolation,
		'wf-row wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-4 wf-col--desk-4': !brandIdIsCCOrOnboardingOrLendMgmtOrRFI,
		'wf-row wf-col--8': brandIdIsCCOrOnboardingOrLendMgmtOrRFI
	})

	const defaultLabelClassName = 'wf-col textAlignLeft'
	const defaultFieldClassName = 'wf-col textAlignLeft'

	const labelClassName = classNames(defaultLabelClassName, {
		'wf-col--6 wf-col--mob-only-12': brandIdIsCCOrOnboardingOrLendMgmtOrRFI,
		'wf-col--12 wf-col--mob-only-12': !brandIdIsCCOrOnboardingOrLendMgmtOrRFI
	})

	const fieldClassName = classNames(defaultFieldClassName, {
		'wf-col--6 wf-col--mob-only-12': brandIdIsCCOrOnboardingOrLendMgmtOrRFI,
		'wf-col--12 wf-col--mob-only-12': !brandIdIsCCOrOnboardingOrLendMgmtOrRFI
	})

	const defaultContractNumberInputClasses =
		'wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-4 wf-col--desk-4 textField'
	const contractNumberHasViolation = contractNumber.errorIds.length > 0
	const contractNumberInputClasses = classNames(
		defaultContractNumberInputClasses,
		{
			HasInputViolation: contractNumberHasViolation
		}
	)

	const defaultCustomerNumberRicohInputClasses =
		'wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-4 wf-col--desk-4 textField'
	const customerNumberRicohHasViolations =
		customerNumberRicohForgotUserId.errorIds.length > 0
	const customerNumberRicohInputClasses = classNames(
		defaultCustomerNumberRicohInputClasses,
		{
			HasInputViolation: customerNumberRicohHasViolations
		}
	)

	const defaultContractNumberRicohInputClasses =
		'wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-4 wf-col--desk-4 textField'
	const contractNumberRicohHasViolations =
		contractNumberRicohForgotUserId.errorIds.length > 0
	const contractNumberRicohInputClasses = classNames(
		defaultContractNumberRicohInputClasses,
		{
			HasInputViolation: contractNumberRicohHasViolations
		}
	)

	const defaultInvoiceNumberInputClasses =
		'wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-4 wf-col--desk-4 textField'
	const invoiceNumberHasViolations =
		invoiceNumberRicohForgotUserId.errorIds.length > 0
	const invoiceNumberInputClasses = classNames(
		defaultInvoiceNumberInputClasses,
		{
			HasInputViolation: invoiceNumberHasViolations
		}
	)

	function onTextInputValidator(elementId, inputValue) {
		return onCustomTextInputValidator(elementId, inputValue, formatMessage)
	}

	return (
		<div>
			<InstructionMessage
				brandIdIsCCOrOnboardingOrLendMgmtOrRFI={
					brandIdIsCCOrOnboardingOrLendMgmtOrRFI
				}
				formatMessage={formatMessage}
			/>
			<p className="wf-u-form-required-indicator">
				<FormattedMessage id="ocra.create-user-id.required.field.label" />
			</p>
			<ForgotUserIdContentInput
				forgotUserIdMessage={forgotUserIdMessage}
				dob={dob}
				emailForgotUserRef={emailForgotUserRef}
				dobForgotUserRef={dobForgotUserRef}
				emailForgotUser={emailForgotUser}
				onTextInputValidator={onTextInputValidator}
				emailInputClasses={emailInputClasses}
				onFocusInput={onFocusInput}
				mode={mode}
				onUpdateInput={onUpdateInput}
				formatMessage={formatMessage}
				labelClassName={labelClassName}
				fieldClassName={fieldClassName}
			/>
			<ForgotUserIdContractNumber
				contractNumber={contractNumber}
				onTextInputValidator={onTextInputValidator}
				onUpdateInput={onUpdateInput}
				formatMessage={formatMessage}
				mode={mode}
				contractNumberInputClasses={contractNumberInputClasses}
			/>
			<ForgotUserIdRicohContractNumber
				customerNumberRicohForgotUserId={customerNumberRicohForgotUserId}
				contractNumberRicohForgotUserId={contractNumberRicohForgotUserId}
				onTextInputValidator={onTextInputValidator}
				invoiceNumberRicohForgotUserId={invoiceNumberRicohForgotUserId}
				onUpdateInput={onUpdateInput}
				formatMessage={formatMessage}
				mode={mode}
				customerNumberRicohInputClasses={customerNumberRicohInputClasses}
				contractNumberRicohInputClasses={contractNumberRicohInputClasses}
				invoiceNumberInputClasses={invoiceNumberInputClasses}
				contractOrInvoice={contractOrInvoice}
				focusedElement={focusedElement}
				onSelectContractOrInvoice={onSelectContractOrInvoice}
			/>
		</div>
	)
}

export function ForgotUserIdFormFooter({
	submitButtonDisabled,
	formatMessage,
	onGetUserId,
	forgotUserIdMessage,
	emailForgotUserRef,
	dobForgotUserRef,
	mode,
	onHideForgotUserId
}) {
	const { messageId } = forgotUserIdMessage

	function onSubmitButton() {
		const canSubmit = !submitButtonDisabled
		if (canSubmit) {
			return onGetUserId()
		} else if (
			mode === SBA ||
			mode === ONBOARD ||
			mode === DLO ||
			mode === COBO ||
			mode === EASYCONNECT
		) {
			dobForgotUserRef.current.validate()
			onFocusUserIdInput('#emailForgotUser')
			emailForgotUserRef.current.validate()
		}
		onFocusUserIdInput('#emailForgotUser')
		emailForgotUserRef.current.validate()
	}
	return (
		<div>
			<Button
				className="ForgotUserIdFormFooterSubmitButton"
				kind="primary"
				disabled={messageId.includes('CLDP1029')}
				onClick={() => onSubmitButton()}
			>
				{formatMessage({
					id: 'forgot-user-id-form.form-footer.button.submit.label'
				})}
			</Button>
			<Button
				className="ForgotUserIdFormFooterCancelButton"
				kind="chromeless"
				onClick={() => onHideForgotUserId()}
			>
				{formatMessage({
					id: 'forgot-user-id-form.form-footer.button.cancel.label'
				})}
			</Button>
		</div>
	)
}

export function ForgotUserIdForm({
	emailForgotUser,
	dob,
	forgotUserIdMessage,
	showForgotUserIdForm,
	onGetUserId,
	onHideForgotUserId,
	onUpdateInput,
	onFocusInput,
	onBlurInput,
	onShowForgotUserIdMessage,
	onHideAllMessages,
	intl,
	onCustomTextInputValidator,
	contractNumber,
	mode,
	submitButtonDisabled,
	customerNumberRicohForgotUserId,
	contractNumberRicohForgotUserId,
	invoiceNumberRicohForgotUserId,
	contractOrInvoice,
	onSelectContractOrInvoice
}) {
	const { formatMessage } = intl
	const emailForgotUserRef = React.createRef()
	const dobForgotUserRef = React.createRef()

	return (
		<ModalDialog
			onClose={onHideForgotUserId}
			title={formatMessage({
				id: 'forgot-user-id-form.modal-dialog.title'
			})}
			footer={
				<ForgotUserIdFormFooter
					submitButtonDisabled={submitButtonDisabled}
					forgotUserIdMessage={forgotUserIdMessage}
					onGetUserId={onGetUserId}
					mode={mode}
					emailForgotUserRef={emailForgotUserRef}
					dobForgotUserRef={dobForgotUserRef}
					formatMessage={formatMessage}
					onHideForgotUserId={onHideForgotUserId}
				/>
			}
			height={'450px'}
			width={'800px'}
			elementToFocusOnClose={'.ForgotUserIdButton'}
			open={showForgotUserIdForm}
		>
			<ForgotUserIdFormContent
				emailForgotUser={emailForgotUser}
				dob={dob}
				emailForgotUserRef={emailForgotUserRef}
				dobForgotUserRef={dobForgotUserRef}
				onHideForgotUserId={onHideForgotUserId}
				onUpdateInput={onUpdateInput}
				formatMessage={formatMessage}
				onFocusInput={onFocusInput}
				onBlurInput={onBlurInput}
				forgotUserIdMessage={forgotUserIdMessage}
				onShowForgotUserIdMessage={onShowForgotUserIdMessage}
				onHideAllMessages={onHideAllMessages}
				onCustomTextInputValidator={onCustomTextInputValidator}
				contractNumber={contractNumber}
				mode={mode}
				customerNumberRicohForgotUserId={customerNumberRicohForgotUserId}
				contractNumberRicohForgotUserId={contractNumberRicohForgotUserId}
				invoiceNumberRicohForgotUserId={invoiceNumberRicohForgotUserId}
				contractOrInvoice={contractOrInvoice}
				onSelectContractOrInvoice={onSelectContractOrInvoice}
			/>
		</ModalDialog>
	)
}

export function InstructionMessage({
	brandIdIsCCOrOnboardingOrLendMgmtOrRFI,
	formatMessage
}) {
	if (brandIdIsCCOrOnboardingOrLendMgmtOrRFI) {
		return (
			<p>
				{formatMessage({
					id: 'forgot-user-id-form.form-content.instruction'
				})}
			</p>
		)
	}
	return (
		<p>
			{formatMessage({
				id: 'forgot-user-id-form.form-content.noncc.instruction'
			})}
		</p>
	)
}
