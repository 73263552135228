import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { WcaForm } from '../components/WcaForm'
import {
	getEmailConfirmation,
	invokeAuthenticateUser,
	invokeForgotPassword,
	invokeNewUser,
	showForgotUserId,
	updateInput,
	focusInput,
	selectCustomerOrContract,
	selectContractOrInvoice,
	selectInvoiceOrSerial,
	clearCustomerOrContract,
	clearContractOrInvoice,
	clearInvoiceOrSerial,
	clearSignOnFields,
	clearRegistrationFields,
	setHideFieldErrors
} from '../actions/login'
import {
	showSignOnErrorMessage,
	hideAllMessages,
	hideRegistrationMessages,
	hideSignOnMessages,
	showRegistrationErrorMessage
} from '../actions/messages'

export const mapStateToProps = state => {
	const {
		mode,
		inputFields,
		focusedElement,
		onlineHelpUrl,
		customerOrContract,
		contractOrInvoice,
		invoiceOrSerial,
		hideFieldErrors
	} = state.loginWidget
	const { showEsignContent, showTermsAndPolicies } = state.termsAndPolicies
	const { email, loginUserId, password } = inputFields
	return {
		mode,
		inputFields,
		focusedElement,
		generalMessage: state.messages.generalMessage,
		registrationMessage: state.messages.registrationMessage,
		signOnButtonDisabled: loginUserId.value === '' || password.value === '',
		signOnMessage: state.messages.signOnMessage,
		emailValid: email.errorIds.length === 0 && email.value.length > 0,
		onlineHelpUrl,
		customerOrContract,
		contractOrInvoice,
		invoiceOrSerial,
		showLanguageDropDown: state.locale.showLanguageDropDown,
		signOnLocaleUrl: state.locale.signOnLocaleUrl,
		hideFieldErrors,
		showEsignContent,
		showTermsAndPolicies
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onAuthenticateUser() {
			dispatch(invokeAuthenticateUser())
			dispatch(hideAllMessages())
		},
		onCreateNewUser() {
			dispatch(invokeNewUser())
			dispatch(hideAllMessages())
		},
		onForgotPassword() {
			dispatch(invokeForgotPassword())
			dispatch(hideAllMessages())
		},
		onForgotUserId(focusedForm) {
			dispatch(showForgotUserId())
			dispatch(hideAllMessages())
		},
		onGetEmailConfirmation(showTermsAndConditionsFooter) {
			dispatch(getEmailConfirmation(showTermsAndConditionsFooter))
			dispatch(hideAllMessages())
		},
		onUpdateInput(elementId, inputValue) {
			dispatch(updateInput(elementId, inputValue))
		},
		onFocusInput(elementId, focusedForm) {
			dispatch(focusInput(elementId))
			if (focusedForm !== 'WcaLogin') {
				dispatch(clearSignOnFields())
				dispatch(hideSignOnMessages())
			}
			if (focusedForm !== 'WcaRegister') {
				dispatch(clearRegistrationFields())
				dispatch(hideRegistrationMessages())
			}
		},
		onShowRegistrationErrorMessage(message) {
			dispatch(showRegistrationErrorMessage(message))
		},
		onShowSignOnErrorMessage(message) {
			dispatch(showSignOnErrorMessage(message))
		},
		onHideAllMessages() {
			dispatch(hideAllMessages())
		},
		onSelectCustomerOrContract(customerOrContract, mode) {
			dispatch(clearCustomerOrContract(customerOrContract, mode))
			dispatch(selectCustomerOrContract(customerOrContract))
		},
		onSelectContractOrInvoice(contractOrInvoice, mode) {
			dispatch(clearContractOrInvoice(contractOrInvoice, mode))
			dispatch(selectContractOrInvoice(contractOrInvoice))
		},
		onSelectInvoiceOrSerial(invoiceOrSerial, mode) {
			dispatch(clearInvoiceOrSerial(invoiceOrSerial, mode))
			dispatch(selectInvoiceOrSerial(invoiceOrSerial))
		},
		onSetHideFieldErrors(hideFieldErrors) {
			dispatch(setHideFieldErrors(hideFieldErrors))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(WcaForm)
)
