import React, { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Button, TextInput, TextInputMask } from '@wf/ria'
import '@wf/ria/lib/css/Utilities/UtilityLinks.css'
import { SystemMessage } from './SystemMessages'
import { RegistrationForm } from './RegistrationForm'
import { ShowLanguageUserSignOn } from './ShowLanguageUserSignOn'
import {
	DOCEX,
	WFORMS,
	RFI,
	ONBOARD,
	SBA,
	RICOH,
	DLO,
	COBO,
	EASYCONNECT,
	WFINFOLEASE,
	PLINFOLEASE
} from '../businessSegmentBrandIds'
import '@wf/ria/lib/css/Flexgrid/Flexgrid.css'

const loginUserIdRef = React.createRef()
const passwordRef = React.createRef()

export function WcaForm({
	mode,
	inputFields,
	emailValid,
	showEsignContent,
	showTermsAndPolicies,
	customerOrContract,
	contractOrInvoice,
	invoiceOrSerial,
	registrationMessage,
	signOnButtonDisabled,
	signOnMessage,
	focusedElement,
	onlineHelpUrl,
	intl,
	signOnLocaleUrl,
	hideFieldErrors,
	showLanguageDropDown,
	onAuthenticateUser,
	onForgotPassword,
	onForgotUserId,
	onGetEmailConfirmation,
	onUpdateInput,
	onFocusInput,
	onHideAllMessages,
	onSelectCustomerOrContract,
	onSelectContractOrInvoice,
	onSelectInvoiceOrSerial,
	onCustomTextInputValidator,
	onShowSignOnErrorMessage,
	onSetHideFieldErrors
}) {
	const { formatMessage } = intl
	const valueMissing = formatMessage({ id: 'required-field.empty.error.label' })
	const [date, setDate] = useState(Date.now())
	const queryParams = new URLSearchParams(window.location.search)
	const localeIsFr = queryParams.get('locale') === 'fr'
	const usernameLocked = queryParams.get('username_locked')
	const usernameUnRegistered = queryParams.get('username_unregistered')
	const userNotfound = queryParams.get('user_notfound')

	useEffect(() => {
		if (
			usernameLocked !== null ||
			usernameUnRegistered !== null ||
			userNotfound !== null
		) {
			const warningMessage = (mode===PLINFOLEASE)?'app-session-username-pl.warning.message':
            				(mode===RICOH)?'app-session-username-ricoh.warning.message':'app-session-username-wf.warning.message';
			onShowSignOnErrorMessage(warningMessage)
		}
	}, [
       		usernameLocked,
       		usernameUnRegistered,
       		userNotfound,
       		onShowSignOnErrorMessage
       	])

	useEffect(() => {
		if (
			(focusedElement !== 'loginUserId' && focusedElement !== 'password') ||
			customerOrContract !== '' ||
			contractOrInvoice !== '' ||
			invoiceOrSerial !== ''
		) {
			const newKey = Date.now()
			setDate(newKey)
		}
	}, [
		focusedElement,
		customerOrContract,
		contractOrInvoice,
		invoiceOrSerial,
		setDate
	])

	useEffect(() => {
		if (hideFieldErrors.loginUserId || hideFieldErrors.password) {
			loginUserIdRef.current.validate('reset')
			passwordRef.current.validate('reset')
			const resetHideFieldErrors = {
				loginUserId: false,
				password: false
			}
			onSetHideFieldErrors(resetHideFieldErrors)
		}
	}, [onSetHideFieldErrors, hideFieldErrors])

	const {
		loginUserId,
		password,
		email,
		existingEmail,
		dob,
		customerNumber,
		customerNumberRicoh,
		contractNumber,
		contractNumberRicoh,
		invoiceNumber,
		serialNumber
	} = inputFields

	const registrationMessageId = registrationMessage.messageId
	const defaultInputClasses = 'textField'
	const emailInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(email)
	})
	const existingEmailInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(existingEmail)
	})
	const customerNumberInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(customerNumber)
	})
	const contractNumberInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(customerNumber)
	})
	const customerNumberRicohInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(customerNumberRicoh)
	})
	const contractNumberRicohInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(contractNumberRicoh)
	})
	const invoiceNumberInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(invoiceNumber)
	})
	const serialNumberInputClasses = classNames(defaultInputClasses, {
		HasInputViolation: hasViolations(serialNumber)
	})

	const instructionId = getInstructionId(mode)
	const rfiMode = /\bmode=rfi\b/gi.test(document.URL)
	const modeSuffix = rfiMode ? ':rfi' : ''
	const brandIdIsCCOrRFIOrOnboardingOrLendMgmt =
		mode === DOCEX ||
		mode === WFORMS ||
		mode === RFI ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	const defaultSignOnHelpCCClasses =
		'wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group'
	const signOnHelpCCClasses = classNames(defaultSignOnHelpCCClasses, {
		hide: !brandIdIsCCOrRFIOrOnboardingOrLendMgmt
	})
	const signOnHelpClasses = classNames({
		hide: brandIdIsCCOrRFIOrOnboardingOrLendMgmt
	})
	const signOnLinksClasses = 'wf-col WcaFlexRowFormGroup'
	const defaultSignOnHelpOffsetClasses = 'hide-on-mobile wf-col'
	const signOnHelpOffsetClasses = classNames(defaultSignOnHelpOffsetClasses, {})
	const defaultDividerClasses = 'Divider'
	const dividerClasses = classNames(defaultDividerClasses, {
		'wf-col-desk--1': brandIdIsCCOrRFIOrOnboardingOrLendMgmt
	})
	const defaultWcaLoginClasses = 'WcaLogin wf-col--tab-8'
	const wcaLoginClasses = classNames(defaultWcaLoginClasses, {
		'wf-col--desk-6': brandIdIsCCOrRFIOrOnboardingOrLendMgmt,
		'wf-col--desk-10': !brandIdIsCCOrRFIOrOnboardingOrLendMgmt
	})
	const textInputLabelClasses =
		'wf-col wf-col--6 wf-col--mob-only-12 textAlignLeft'
	const textInputClasses = 'wf-col wf-col--6 wf-col--mob-only-12'
	const loginButtonClasses = 'wf-col hide-on-mobile'
	function isEmpty(str) {
		return str.value.length === 0
	}

	function hasViolations(str) {
		return str?.errorIds.length > 0
	}

	function validate(errorSchema) {
		const errors = {}
		for (let [id, validators] of Object.entries(errorSchema)) {
			for (let i = 0; i < validators.length; i++) {
				if (validators[i]) {
					errors[id] = true
				}
			}
		}
		return errors
	}

	function registrationButtonDisabled() {
		if (
			mode === SBA ||
			mode === ONBOARD ||
			mode === DLO ||
			mode === COBO ||
			mode === EASYCONNECT
		) {
			const errorSchema = {
				email: [isEmpty(email), hasViolations(email)],
				dob: [isEmpty(dob), hasViolations(dob)]
			}
			const errors = validate(errorSchema)
			return Object.keys(errors).length !== 0
		}
		if (
			mode !== DOCEX &&
			mode !== WFORMS &&
			mode !== ONBOARD &&
			mode !== SBA &&
			mode !== RFI &&
			mode !== DLO &&
			mode !== COBO &&
			mode !== EASYCONNECT
		) {
			const errorSchema = {
				customerNumber: [hasViolations(customerNumber)],
				contractNumber: [hasViolations(contractNumber)],
				serialNumber: [hasViolations(serialNumber)],
				invoiceNumber: [hasViolations(invoiceNumber)],
				contractNumberRicoh: [hasViolations(contractNumberRicoh)],
				customerNumberRicoh: [hasViolations(customerNumberRicoh)]
			}
			const errors = validate(errorSchema)
			if (Object.keys(errors).length !== 0) {
				return true
			}
			if (mode !== RICOH) {
				const violations = [
					isEmpty(customerNumber) && isEmpty(contractNumber),
					isEmpty(email),
					isEmpty(invoiceNumber) && isEmpty(serialNumber)
				]
				return violations.filter(isEmpty => isEmpty).length > 0
			} else {
				const contractOrInvoiceEmpty =
					isEmpty(contractNumberRicoh) && isEmpty(invoiceNumber)
				return isEmpty(customerNumberRicoh) || contractOrInvoiceEmpty
			}
		}
		return hasViolations(email) || isEmpty(email)
	}

	function getInstructionId(mode) {
		return `wca-form.new-user-registration.${mode}.instruction`
	}

	const registrationMessageIdIsArray = Array.isArray(registrationMessageId)
	if (
		(!registrationMessageIdIsArray &&
			registrationMessageId &&
			registrationMessageId.search('api-service-error') === -1 &&
			!hasViolations(email)) ||
		(registrationMessageIdIsArray && registrationMessageId.length === 0)
	) {
		onHideAllMessages()
	}

	function onFocusFactory(focusedForm) {
		return event => {
			const focusedElement = event.target.id
			onFocusInput(focusedElement, focusedForm)
		}
	}

	function onChange(event) {
		const id = event.target.id
		const value = event.target.value
		if (id === 'userEmail') {
			onUpdateInput('email', value)
		} else if (id === 'existingUserEmail') {
			onUpdateInput('existingEmail', value)
		} else {
			onUpdateInput(id, value)
		}
	}

	function onEnterKeyDown(event) {
		if (event.keyCode === 13 && !signOnButtonDisabled) {
			onAuthenticateUser()
		}
	}
	function clickSignon() {
		if (!signOnButtonDisabled) {
			onAuthenticateUser()
		}
		if (signOnButtonDisabled) {
			onShowSignOnErrorMessage('app-invalid-sign-on')
		}
		const input = document.getElementById('loginUserId')
		if (input) {
			input.focus()
		}
	}
	function onUserSignOnHere(signOnLocaleUrl) {
		window.location.replace(signOnLocaleUrl)
	}

	function onTextInputValidator(elementId, inputValue) {
		return onCustomTextInputValidator(elementId, inputValue, formatMessage)
	}

	if (showEsignContent || showTermsAndPolicies) {
		return <Fragment />
	}
	return (
		<form className="WcaForm" action="#" noValidate>
			<div className="wf-row WcaFormContainer">
				<div className="wf-col--mob-only-12 wf-col--tab-12 wf-col--desk-6">
					<div className={wcaLoginClasses}>
						<div autoComplete="off" autoCorrect="off">
							<HeaderText mode={mode} />
							<div className="wf-row">
								<div className="wf-row form-group SignOnError">
									<div className="wf-col--6 hide-on-mobile"> </div>
									<div className="wf-col--mob-only-12 wf-col--tab-12 wf-col--desk-8">
										<SystemMessage
											className="SignOnMessage"
											show={signOnMessage.show}
											iconKind={signOnMessage.iconKind}
											messageId={signOnMessage.messageId}
										/>
									</div>
								</div>
							</div>
							<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
								<div className="wf-col--12">
									<TextInput
										key={date}
										aria-label={formatMessage({
											id: 'wca-form.user-id.aria-label'
										})}
										label={formatMessage({
											id: 'wca-form.user-id.label'
										})}
										aria-required={true}
										required={false}
										id="loginUserId"
										ref={loginUserIdRef}
										type="text"
										size="27"
										value={loginUserId.value}
										onChange={onChange}
										onFocus={onFocusFactory('WcaLogin')}
										className="textField wf-row api-xsmall-margin-bottom"
										labelClassName={textInputLabelClasses}
										fieldClassName={textInputClasses}
										nativeValidationMessages={{
											valueMissing
										}}
									/>
								</div>
							</div>

							<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
								<div className="wf-col--12 PasswordField">
									<TextInputMask
										key={date}
										aria-label={formatMessage({
											id: 'wca-form.password.aria-label'
										})}
										label={formatMessage({
											id: 'wca-form.password.label'
										})}
										aria-required={true}
										required={false}
										id="password"
										ref={passwordRef}
										type="password"
										size="27"
										value={password.value}
										onChange={onChange}
										onFocus={onFocusFactory('WcaLogin')}
										onKeyDown={onEnterKeyDown}
										className="textField wf-row api-xsmall-margin-bottom"
										labelClassName={textInputLabelClasses}
										fieldClassName={textInputClasses}
										nativeValidationMessages={{
											valueMissing
										}}
									/>
								</div>
							</div>
							<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
								<div className={loginButtonClasses} />
								<div className="wf-col">
									<Button
										className="SignOnButton"
										kind="primary"
										onClick={clickSignon}
									>
										<FormattedMessage id="wca-form.button.sign-on.label" />
									</Button>
								</div>
							</div>
							<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group SignOnLinks">
								<div className={loginButtonClasses} />
								<div className={signOnLinksClasses}>
									<div className="ForgotPasswordRow">
										<Button
											kind="simple"
											className="ForgotPasswordButton"
											id="ForgotPasswordButton"
											onClick={onForgotPassword}
											tabIndex="0"
										>
											<FormattedMessage id="wca-form.button.forgot-password.label" />
										</Button>
									</div>

									<div className="ForgotUserIdRow">
										<Button
											kind="simple"
											className="ForgotUserIdButton"
											id="ForgotUserIdButton"
											onClick={onForgotUserId}
											onFocus={onFocusInput}
											tabIndex="0"
										>
											<FormattedMessage id="wca-form.button.forgot-user-id.label" />
										</Button>
									</div>
									<SignOn
										show={!localeIsFr}
										signOnHelpClasses={signOnHelpClasses}
										onlineHelpUrl={onlineHelpUrl}
									/>
								</div>
							</div>
							<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group SignOnLinks LocaleIsFrench">
								<div className={loginButtonClasses}> </div>
								<SignOn
									show={localeIsFr}
									signOnHelpClasses={signOnHelpClasses}
									onlineHelpUrl={onlineHelpUrl}
								/>
							</div>

							<div className={signOnHelpCCClasses}>
								<div className={signOnHelpOffsetClasses}> </div>
								<div className="wf-col">
									<a
										href={onlineHelpUrl}
										target="_blank"
										rel="noopener noreferrer"
										className="wf-u-link-open-new-window SignOnHelpUrl"
										tabIndex="0"
									>
										<FormattedMessage id="wca-form.link.sign-on-help.label" />
										<span className="wf-u-visually-hidden">
											<FormattedMessage id="wca-form.link.additional-aria-text.text" />
										</span>
									</a>
								</div>
							</div>

							<ShowLanguageUserSignOn
								mode={mode}
								formatMessage={formatMessage}
								onUserSignOnHere={onUserSignOnHere}
								signOnLocaleUrl={signOnLocaleUrl}
								showLanguageDropDown={showLanguageDropDown}
							/>
						</div>
					</div>
				</div>

				<div className={dividerClasses} />
				<div className="wf-col--mob-only-12 wf-col--tab-12 wf-col--desk-5">
					<div className="WcaRegister wf-col--tab-8 wf-col--desk-10">
						<div autoComplete="off" autoCorrect="off">
							<h2
								className={`form-header form-group ${
									mode === WFINFOLEASE ? 'WBRegistrationText' : ''
								}`}
							>
								<FormattedMessage id="wca-form.new-user-registration.form-header.text" />
							</h2>
							<div className="RegistrationHeader">
								<FormattedMessage
									id={instructionId + modeSuffix}
									defaultMessage="{defaultValue}"
									values={{
										defaultValue: <FormattedMessage id={instructionId} />
									}}
								/>
							</div>
							<RegistrationForm
								focusedElement={focusedElement}
								mode={mode}
								formatMessage={formatMessage}
								email={email}
								existingEmail={existingEmail}
								dob={dob}
								customerNumber={customerNumber}
								customerNumberRicoh={customerNumberRicoh}
								contractNumber={contractNumber}
								contractNumberRicoh={contractNumberRicoh}
								invoiceNumber={invoiceNumber}
								serialNumber={serialNumber}
								customerOrContract={customerOrContract}
								contractOrInvoice={contractOrInvoice}
								invoiceOrSerial={invoiceOrSerial}
								registrationMessage={registrationMessage}
								customerNumberInputClasses={customerNumberInputClasses}
								contractNumberInputClasses={contractNumberInputClasses}
								customerNumberRicohInputClasses={
									customerNumberRicohInputClasses
								}
								contractNumberRicohInputClasses={
									contractNumberRicohInputClasses
								}
								invoiceNumberInputClasses={invoiceNumberInputClasses}
								serialNumberInputClasses={serialNumberInputClasses}
								emailValid={emailValid}
								showEsignContent={showEsignContent}
								showTermsAndPolicies={showTermsAndPolicies}
								registrationButtonDisabled={registrationButtonDisabled()}
								emailInputClasses={emailInputClasses}
								existingEmailInputClasses={existingEmailInputClasses}
								hideFieldErrors={hideFieldErrors}
								onSetHideFieldErrors={onSetHideFieldErrors}
								onChange={onChange}
								onUpdateInput={onUpdateInput}
								onFocusFactory={onFocusFactory}
								onSelectCustomerOrContract={onSelectCustomerOrContract}
								onSelectContractOrInvoice={onSelectContractOrInvoice}
								onSelectInvoiceOrSerial={onSelectInvoiceOrSerial}
								onGetEmailConfirmation={onGetEmailConfirmation}
								onTextInputValidator={onTextInputValidator}
							/>
						</div>
					</div>
				</div>
				<Footer mode={mode} />
				{/* <InfoleaseRegistrationVideoplayer
					mode={mode}
					formatMessage={formatMessage}
				/> */}
			</div>
		</form>
	)
}

export function HeaderText({ mode }) {
	if (mode === WFINFOLEASE) {
		return (
			<>
				<h1 className="wf-col form-header WBLoginHeaderText">
					<FormattedMessage id="wca-form.sign-on.form-header.label-wb" />
				</h1>
				<div className="WBLoginSubtext">
					<FormattedMessage id="wca-form.sign-on.form-header.label-wb-subtext" />
				</div>
			</>
		)
	}
	return (
		<h1 className="wf-col form-header">
			<FormattedMessage id="wca-form.sign-on.form-header.label" />
		</h1>
	)
}

export function InfoleaseRegistrationVideoplayer({ mode, formatMessage }) {
	const params = new URLSearchParams(window.location.search)
	const canadaSignon = params.get('mode')
	const videoSrc =
		'https://www.wellsfargomedia.com/wholesale/V19-1645-1_CLDP_MyAccounts_WF_Version_720P-3mbps.mp4'
	if (canadaSignon !== 'canada' && mode === WFINFOLEASE) {
		return (
			<div data-wf-efmya-id="registrationvideo">
				<div data-wf-efmya-id="registrationvideoHeader">
					<FormattedMessage id="video.player.header" />
				</div>
				<div>
					<h2>
						<FormattedMessage id="video.player.disclosure" />
					</h2>
				</div>
				<div data-wf-efmya-id="registrationvideowrapper">
					<iframe
						title={formatMessage({
							id: 'video.player.title'
						})}
						src={videoSrc}
					></iframe>
				</div>
			</div>
		)
	}
	return null
}
export function Footer({ mode }) {
	if (mode === EASYCONNECT) {
		return (
			<div className="wf-col--12 FooterWrapper">
				<div className="wf-col--mob-only-12 wf-col--tab-12 wf-col--desk-10 WrapperText wf-text7">
					<div className="wf-text7 InvestmentText">
						<FormattedMessage id="footer.investment-and-insurance-products.label" />
					</div>
					<ul className="wf-list-styled">
						<li className="wf-text7">
							<FormattedMessage id="footer.investment.not-insured.reason.1" />
						</li>
						<li className="wf-text7">
							<FormattedMessage id="footer.investment.not-deposit.reason.2" />
						</li>
						<li className="wf-text7">
							<FormattedMessage id="footer.investment.subject-to-investment-risks.reason.3" />
						</li>
					</ul>
				</div>
				<div className="DisclosureText wf-text7">
					<span>
						<FormattedMessage id="footer.deposit.products-disclosure" />
					</span>
				</div>
			</div>
		)
	}
	return null
}

export function SignOn({ show, signOnHelpClasses, onlineHelpUrl }) {
	if (show) {
		return (
			<div className={signOnHelpClasses}>
				<a
					href={onlineHelpUrl}
					target="_blank"
					rel="noopener noreferrer"
					className="wf-button wf-button--simple SignOnHelpUrl wf-u-link-export"
					key="SignOnHelpUrl"
					tabIndex="0"
				>
					<FormattedMessage id="wca-form.link.sign-on-help.label" />
					<span className="wf-u-visually-hidden">
						<FormattedMessage id="wca-form.link.additional-aria-text.text" />
					</span>
				</a>
			</div>
		)
	}
	return <Fragment />
}
