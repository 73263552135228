import { RFI, OCRAEMEA, OCRAANZ } from './businessSegmentBrandIds'
import { convertLocaleForOCRA } from './appInit'

export function createWcaPingWidget(
	{
		appId,
		brandId,
		helpUrl,
		targetUrl,
		panelTitleColor,
		panelBackgroundColor,
		errorFontColor,
		buttonColor,
		buttonFontColor,
		panelFontFamily
	},
	mode
) {
	const params = new URLSearchParams(window.location.search)
	const locale = params.get('locale') || getDefaultLocale(mode)
	const modeParam = params.get('mode')
	const end = targetUrl.indexOf('.com') + 4
	const baseUrl = targetUrl.substring(0, end)
	console.log('target  url...', targetUrl)
	console.log('login url...', baseUrl)
	const loginWidgetStyle = {
		panelTitleColor,
		panelBackgroundColor,
		errorFontColor,
		buttonColor,
		buttonFontColor,
		panelFontFamily
	}
	return new window.WCAWidget({
		appId,
		brandId,
		helpURL: setHelpUrl(mode, modeParam, locale),
		targetUrl,
		loginUrl: baseUrl,
		isPingEnabled: true,
		language: setLanguage(),
		panelStyle: loginWidgetStyle,
		userIdLabel: 'User ID',
		isLoginEmbedded: true,
		selfRegURL: 'index.html',
		loginErrorContainer: document.getElementById('wcaErrorMessages')
	})
}

export function setLanguage() {
	const params = new URLSearchParams(window.location.search)
	const mode = params.get('mode')
	const isOcraMode = mode && mode.includes('ocra') && !params.has('faqs')
	const locale = isOcraMode
		? convertLocaleForOCRA(params.get('locale'), mode)
		: params.get('locale')
	switch (locale) {
		case 'en':
			return 'en_US'
		case 'fr':
			return 'fr_CA'
		case 'de_DE':
			return 'de_DE'
		case 'fr_FR':
			return 'fr_FR'
		case 'en_GB':
			return 'en_GB'
		case 'au_AU':
			return 'au_AU'
		default:
			return 'en_US'
	}
}

export function setHelpUrl(mode, modeParam, locale) {
	const currentURL = window.location.href
	let helpUrl = `${currentURL}?faqs`
	if (window.location.search) {
		helpUrl = currentURL.replace(
			window.location.search,
			`?mode=${modeParam}&locale=${locale}&faqs`
		)
	}
	if (mode === RFI) {
		if (typeof helpUrl === 'string' && modeParam !== 'rfi') {
			return helpUrl.replace(`mode=${modeParam}`, `mode=rfi`)
		}
	}
	return helpUrl
}

export function getDefaultLocale(mode = '') {
	if (mode === OCRAEMEA) {
		return 'en_GB'
	}
	if (mode === OCRAANZ) {
		return 'au_AU'
	}
	return 'en_US'
}
