import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { ESignConsent } from '../components/ESignConsent'
import { showCreateUserId } from '../actions/login'
import {
	hideEsignConsent,
	getTermsOfUse,
	declineEsignConsent,
	downloadEsignConsent,
	getRegistrationTermsOfUse
} from '../actions/termsAndPolicies'

export const mapStateToProps = state => {
	return {
		show: state.termsAndPolicies.showEsignContent,
		showESignFooter: state.termsAndPolicies.showESignFooter,
		showEsign: state.termsAndPolicies.showEsign,
		termsToDisplay: state.termsAndPolicies[state.termsAndPolicies.termType],
		mode: state.loginWidget.mode
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onShowCreateUserId() {
			dispatch(showCreateUserId())
		},
		onHideEsignConsent() {
			dispatch(hideEsignConsent())
		},
		onDeclineEsignConsent() {
			dispatch(declineEsignConsent())
		},
		onDownloadEsignConsent() {
			dispatch(downloadEsignConsent())
		},
		onGetRegistrationTermsOfUse(showEsign, showESignFooter, showTermsFooter) {
			dispatch(
				getRegistrationTermsOfUse(showEsign, showESignFooter, showTermsFooter)
			)
		},
		onGetTermsOfUse(showEsign, showESignFooter, showTermsFooter) {
			dispatch(getTermsOfUse(showEsign, showESignFooter, showTermsFooter))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ESignConsent)
)
