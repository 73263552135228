export function createBobWidgetFactory(baseUrl, onInit) {
	return window.auth.bridge.init(
		{
			baseUrl
		},
		onInit
	)
}

export function getBobLoginWidgetForOrigin(
	baseUrl,
	forgotUsernameUrl,
	bobServiceType
) {
	return () => {
		return window.auth.bridge.loginWidget.get({
			appendTo: '#loginWidgetContainer',
			baseUrl,
			forgotUsernameUrl,
			saveUsername: false,
			showTitle: true,
			signUp: false,
			submitText: 'Sign On',
			titleText: 'Sign On',
			serviceType: bobServiceType,
			origin: 'cob'
		})
	}
}
