import React, { useEffect, useState } from 'react'
import { RadioButton, TextInput } from '@wf/ria'
import { HelpButton } from '@wf/ria'
import { IconHelp } from '@wf/ria-icons'

const invoiceNumberRicohRef = React.createRef()
const customerNumberRicohRef = React.createRef()
const contractNumberRicohRef = React.createRef()

export function CustomerContractInvoiceNumberRICOH({
	focusedElement,
	formatMessage,
	customerNumberRicoh,
	contractNumberRicoh,
	invoiceNumber,
	contractOrInvoice,
	customerNumberRicohInputClasses,
	contractNumberRicohInputClasses,
	invoiceNumberInputClasses,
	onChange,
	onFocusFactory,
	onSelectContractOrInvoice,
	onTextInputValidator
}) {
	const [key, setKey] = useState(Date.now())
	const valueMissing = formatMessage({ id: 'required-field.empty.error.label' })
	const contractOrInvoiceInputDisabled =
		!contractOrInvoice && !!customerNumberRicoh

	useEffect(() => {
		if (
			focusedElement !== 'email' &&
			focusedElement !== 'invoiceNumber' &&
			focusedElement !== 'customerNumberRicoh' &&
			focusedElement !== 'contractNumberRicoh'
		) {
			invoiceNumberRicohRef.current.validate('reset')
			customerNumberRicohRef.current.validate('reset')
			contractNumberRicohRef.current.validate('reset')

			const newKey = Date.now()
			setKey(newKey)
		}
	}, [focusedElement, setKey])

	function getContractOrInvoice(contractOrInvoice) {
		if (contractOrInvoice === 'contract') {
			return 'contractNumberRicoh'
		}
		return 'invoiceNumber'
	}

	function getContractOrInvoiceInputClasses(contractOrInvoice) {
		if (contractOrInvoice === 'contract') {
			return contractNumberRicohInputClasses
		}
		return invoiceNumberInputClasses
	}

	function invokeOnSelectContractOrInvoice(event) {
		onSelectContractOrInvoice(event.target.value)
	}

	function showHideContractTextInputField(customerOrContract) {
		const defaultShowHideContractClasses = 'wf-col hide'
		if (customerOrContract === 'contract') {
			const showHideContractClasses = 'wf-col'
			return showHideContractClasses
		}
		return defaultShowHideContractClasses
	}

	function showHideInvoiceTextInputField(customerOrContract) {
		const defaultShowHideInvoiceClasses = 'wf-col hide'
		if (customerOrContract !== 'contract') {
			const showHideInvoiceClasses = 'wf-col'
			return showHideInvoiceClasses
		}
		return defaultShowHideInvoiceClasses
	}
	return (
		<div className="CustomerNumber WcaRegistrationFormGroup">
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col" />
				<div className="wf-col">
					<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns">
						<div className="wf-col--11 CustomerNumberRadioButton">
							<RadioButton
								label={formatMessage({
									id:
										'wca-form.new-user-registration.customer-and-contract-number-ricoh.label'
								})}
								value={'contract'}
								name="customerAndContractNumber"
								onChange={invokeOnSelectContractOrInvoice}
								checked={contractOrInvoice === 'contract'}
							/>
						</div>
						<div className="wf-col--1 CustomerNumberHelpButton">
							<HelpButton
								helpBody={
									<>
										{formatMessage({
											id:
												'wca-form.new-user-registration.ricoh.customercontract'
										})}
									</>
								}
								kind="chromeless"
								aria-label={formatMessage({
									id: 'wca-form.new-user-registration.ricoh.customercontract'
								})}
								centerIcon={<IconHelp size="small" />}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col" />
				<div className="wf-col CustomerNumberRadioButton">
					<RadioButton
						label={formatMessage({
							id:
								'wca-form.new-user-registration.customer-and-invoice-number.label'
						})}
						value={'invoice'}
						name="CustomerAndInvoiceNumber"
						onChange={invokeOnSelectContractOrInvoice}
						checked={contractOrInvoice === 'invoice'}
					/>
				</div>
			</div>

			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col">
					<label htmlFor={getContractOrInvoice(contractOrInvoice)}>
						{formatMessage({
							id: 'wca-form.new-user-registration.customer-number.label'
						})}
					</label>
				</div>
				<div className="wf-col">
					<TextInput
						key={key}
						aria-label={formatMessage({
							id: 'wca-form.new-user-registration.customer-number.label'
						})}
						aria-required={true}
						required={true}
						labelHidden={true}
						disabled={contractOrInvoiceInputDisabled}
						id={'customerNumberRicoh'}
						ref={customerNumberRicohRef}
						type="text"
						size="25"
						value={customerNumberRicoh.value}
						onChange={onChange}
						onFocus={onFocusFactory('WcaRegister')}
						className={customerNumberRicohInputClasses}
						customValidators={[
							value => onTextInputValidator('customerNumberRicoh', value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>
			</div>
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col contractOrInvoice">
					<label htmlFor={getContractOrInvoice(contractOrInvoice)}>
						{formatMessage({
							id:
								'wca-form.new-user-registration.contract-or-invoice-number.label'
						})}
					</label>
				</div>
				<div className={showHideContractTextInputField(contractOrInvoice)}>
					<TextInput
						key={key}
						aria-label={formatMessage({
							id:
								'wca-form.new-user-registration.contract-or-invoice-number.label'
						})}
						aria-required={true}
						required={true}
						labelHidden={true}
						disabled={contractOrInvoiceInputDisabled}
						id={'contractNumberRicoh'}
						ref={contractNumberRicohRef}
						type="text"
						size="25"
						value={contractNumberRicoh.value}
						onChange={onChange}
						onFocus={onFocusFactory('WcaRegister')}
						className={getContractOrInvoiceInputClasses('contract')}
						customValidators={[
							value =>
								onTextInputValidator(getContractOrInvoice('contract'), value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>

				<div className={showHideInvoiceTextInputField(contractOrInvoice)}>
					<TextInput
						aria-label={formatMessage({
							id:
								'wca-form.new-user-registration.contract-or-invoice-number.label'
						})}
						aria-required={true}
						required={true}
						labelHidden={true}
						disabled={contractOrInvoiceInputDisabled}
						id={'invoiceNumber'}
						ref={invoiceNumberRicohRef}
						type="text"
						size="25"
						value={invoiceNumber.value}
						onChange={onChange}
						onFocus={onFocusFactory('WcaRegister')}
						className={getContractOrInvoiceInputClasses('invoice')}
						customValidators={[
							value =>
								onTextInputValidator(getContractOrInvoice('invoice'), value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>
			</div>
		</div>
	)
}
