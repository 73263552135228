import { connect } from 'react-redux'
import { TermsAndConditions } from '../components/TermsAndConditions'
import { showCreateUserId } from '../actions/login'
import {
	hideTermsAndPolicies,
	declineTermsAndPolicies
} from '../actions/termsAndPolicies'
import { injectIntl } from 'react-intl'

export const mapStateToProps = state => {
	return {
		show: state.termsAndPolicies.showTermsAndPolicies,
		showTermsFooter: state.termsAndPolicies.showTermsFooter,
		termsToDisplay: state.termsAndPolicies[state.termsAndPolicies.termType],
		termType: state.termsAndPolicies.termType,
		mode: state.loginWidget.mode
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onShowCreateUserId() {
			dispatch(showCreateUserId())
		},
		onHideTerms() {
			dispatch(hideTermsAndPolicies())
		},
		onDeclineTerms() {
			dispatch(declineTermsAndPolicies())
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(TermsAndConditions)
)
