import { fetchRegistrationTermsOfUse } from '../services/fetchRegistrationTermsOfUse'
import { fetchAttorneyTermsOfUse } from '../services/fetchAttorneyTermsOfUse'
import { fetchAttorneyPrivacyPolicy } from '../services/fetchAttorneyPrivacyPolicy'
import { fetchTermsOfUse } from '../services/fetchTermsOfUse'
import { fetchPrivacyPolicy } from '../services/fetchPrivacyPolicy'
import { declineTerms } from '../services/declineTerms'
import { showGeneralMessage, hideAllMessages } from './messages'
import { fetchEsignConsent } from '../services/fetchEsignConsent'
import { declineEsign } from '../services/declineEsign'
import { downloadEsign } from '../services/downloadEsign'

export function getRegistrationTermsOfUse(showEsign, showTermsFooter) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			if (showEsign) {
				dispatch(getEsignConsent(showEsign, showTermsFooter))
			} else {
				fetchRegistrationTermsOfUse()
					.then(registrationTermsOfUseResponse => {
						if (registrationTermsOfUseResponse.success) {
							dispatch(
								showTermsAndPolicies(
									registrationTermsOfUseResponse.text,
									'registrationTerms',
									showTermsFooter
								)
							)
						} else {
							dispatch(
								getRegistrationTermsOfUseFailure(
									registrationTermsOfUseResponse.message
								)
							)
						}
						resolve(registrationTermsOfUseResponse)
					})
					.catch(registrationTermsOfUseError => {
						dispatch(
							getRegistrationTermsOfUseFailure(
								registrationTermsOfUseError.error
							)
						)
						reject(registrationTermsOfUseError)
					})
			}
		})
	}
}

export function getEsignConsent(showEsign, showESignFooter) {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const registrationTerms = getState().termsAndPolicies.registrationTerms
			if (showEsign) {
				if (registrationTerms) {
					dispatch(
						showESignConsent(
							registrationTerms,
							'registrationTerms',
							showESignFooter
						)
					)
					resolve(registrationTerms)
				} else {
					const getESignConsentPayload = {
						agreementName: 'esign'
					}
					fetchEsignConsent(getESignConsentPayload)
						.then(registrationTermsOfUseResponse => {
							if (registrationTermsOfUseResponse.success) {
								dispatch(
									showESignConsent(
										registrationTermsOfUseResponse.text,
										'registrationTerms',
										showESignFooter
									)
								)
							} else {
								dispatch(
									getRegistrationTermsOfUseFailure(
										registrationTermsOfUseResponse.message
									)
								)
							}
							resolve(registrationTermsOfUseResponse)
						})
						.catch(registrationTermsOfUseError => {
							dispatch(
								getRegistrationTermsOfUseFailure(
									registrationTermsOfUseError.error
								)
							)
							reject(registrationTermsOfUseError)
						})
				}
			}
		})
	}
}

export const SHOW_ESIGN_CONSENT = 'SHOW_ESIGN_CONSENT'

export function showESignConsent(content, termType, showESignFooter) {
	return {
		type: SHOW_ESIGN_CONSENT,
		content,
		termType,
		showESignFooter
	}
}

export const HIDE_ESIGN_CONSENT = 'HIDE_ESIGN_CONSENT'

export function hideEsignConsent() {
	return {
		type: HIDE_ESIGN_CONSENT
	}
}

export const DECLINE_E_SIGN_CONTENT = 'DECLINE_E_SIGN_CONTENT'

export function declineEsignContent() {
	return {
		type: DECLINE_E_SIGN_CONTENT
	}
}

export function declineEsignConsent() {
	return (dispatch, getState) => {
		const emailId = getState().loginWidget.inputFields.email.value
		const payload = {
			agreementName: 'esign',
			emailId
		}
		return new Promise((resolve, reject) => {
			declineEsign(payload)
				.then(declineEsignConsentResponse => {
					if (declineEsignConsentResponse.success) {
						dispatch(declineEsignContent())
					} else {
						dispatch(
							showGeneralMessage(
								declineEsignConsentResponse.messageCode,
								'error'
							)
						)
						dispatch(declineEsignContent())
					}
					resolve(declineEsignConsentResponse)
				})
				.catch(declineEsignError => {
					dispatch(showGeneralMessage(declineEsignError.error))
					reject(declineEsignError)
				})
		})
	}
}

export const DOWNLOAD_E_SIGN_CONTENT = 'DOWNLOAD_E_SIGN_CONTENT'

export function downloadEsignContent() {
	return {
		type: DOWNLOAD_E_SIGN_CONTENT
	}
}

export function downloadEsignConsent() {
	return async function downloadEsignConsentThunk(dispatch) {
		try {
			await downloadEsign()
			dispatch(downloadEsignContent())
		} catch (downloadEsignError) {
			dispatch(showGeneralMessage(downloadEsignError.error))
		}
	}
}

export const GET_REGISTRATION_TERMS_OF_USE_FAILURE =
	'GET_REGISTRATION_TERMS_OF_USE_FAILURE'

export function getRegistrationTermsOfUseFailure(message) {
	return {
		type: GET_REGISTRATION_TERMS_OF_USE_FAILURE,
		message
	}
}

export function getBorrowerTermsOfUse() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const borrowerTerms = getState().termsAndPolicies.borrowerTerms
			if (borrowerTerms) {
				dispatch(showTermsAndPolicies(borrowerTerms, 'borrowerTerms'))
				resolve(borrowerTerms)
			} else {
				fetchTermsOfUse()
					.then(borrowerTermsOfUseResponse => {
						if (borrowerTermsOfUseResponse.success) {
							dispatch(
								showTermsAndPolicies(
									borrowerTermsOfUseResponse.text,
									'borrowerTerms'
								)
							)
						} else {
							dispatch(
								getBorrowerTermsOfUseFailure(borrowerTermsOfUseResponse.message)
							)
						}
						resolve(borrowerTermsOfUseResponse)
					})
					.catch(borrowerTermsOfUseError => {
						dispatch(
							getBorrowerTermsOfUseFailure(borrowerTermsOfUseError.error)
						)
						reject(borrowerTermsOfUseError)
					})
			}
		})
	}
}

export const GET_BORROWER_TERMS_OF_USE_FAILURE =
	'GET_BORROWER_TERMS_OF_USE_FAILURE'

export function getBorrowerTermsOfUseFailure(message) {
	return {
		type: GET_BORROWER_TERMS_OF_USE_FAILURE,
		message
	}
}

export function getTermsOfUse(showEsign, showTermsFooter) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			if (showEsign) {
				dispatch(getEsignConsentTermsOfUse(showEsign, showTermsFooter))
			} else {
				fetchTermsOfUse()
					.then(nonCCTermsOfUseResponse => {
						dispatch(hideAllMessages())
						if (nonCCTermsOfUseResponse.success) {
							dispatch(
								showTermsAndPolicies(
									nonCCTermsOfUseResponse.text,
									'nonCCTerms',
									showTermsFooter
								)
							)
						} else {
							dispatch(
								getNonCCTermsOfUseFailure(nonCCTermsOfUseResponse.message)
							)
							dispatch(
								showGeneralMessage(nonCCTermsOfUseResponse.messageCode, 'error')
							)
							dispatch(declineEsignContent())
						}
						resolve(nonCCTermsOfUseResponse)
					})
					.catch(nonCCTermsOfUseError => {
						dispatch(getNonCCTermsOfUseFailure(nonCCTermsOfUseError.error))
						reject(nonCCTermsOfUseError)
					})
			}
		})
	}
}

export function getEsignConsentTermsOfUse(showEsign, showESignFooter) {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const { nonCCTerms } = getState().termsAndPolicies
			if (showEsign) {
				if (nonCCTerms) {
					dispatch(showESignConsent(nonCCTerms, 'nonCCTerms', showESignFooter))
					resolve(nonCCTerms)
				} else {
					const getESignConsentPayload = {
						agreementName: 'esign'
					}
					fetchEsignConsent(getESignConsentPayload)
						.then(nonCCTermsOfUseResponse => {
							if (nonCCTermsOfUseResponse.success) {
								dispatch(
									showESignConsent(
										nonCCTermsOfUseResponse.text,
										'nonCCTerms',
										showESignFooter
									)
								)
							} else {
								dispatch(
									getNonCCTermsOfUseFailure(nonCCTermsOfUseResponse.message)
								)
							}
							resolve(nonCCTermsOfUseResponse)
						})
						.catch(nonCCTermsOfUseError => {
							dispatch(getNonCCTermsOfUseFailure(nonCCTermsOfUseError.error))
							reject(nonCCTermsOfUseError)
						})
				}
			}
		})
	}
}

export const GET_NON_CC_TERMS_OF_USE_FAILURE = 'GET_NON_CC_TERMS_OF_USE_FAILURE'

export function getNonCCTermsOfUseFailure(message) {
	return {
		type: GET_NON_CC_TERMS_OF_USE_FAILURE,
		message
	}
}

export function getPrivacyPolicy() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const { nonCCPrivacySecurityAndLegal } = getState().termsAndPolicies
			if (nonCCPrivacySecurityAndLegal) {
				dispatch(
					showTermsAndPolicies(
						nonCCPrivacySecurityAndLegal,
						'nonCCPrivacySecurityAndLegal'
					)
				)
				resolve(nonCCPrivacySecurityAndLegal)
			} else {
				fetchPrivacyPolicy()
					.then(nonCCPrivacyPolicyResponse => {
						dispatch(hideAllMessages())
						if (nonCCPrivacyPolicyResponse.success) {
							dispatch(
								showTermsAndPolicies(
									nonCCPrivacyPolicyResponse.text,
									'nonCCPrivacySecurityAndLegal'
								)
							)
						} else {
							dispatch(
								getNonCCPrivacyPolicyFailure(nonCCPrivacyPolicyResponse.message)
							)
						}
						resolve(nonCCPrivacyPolicyResponse)
					})
					.catch(nonCCPrivacyPolicyError => {
						dispatch(
							getNonCCPrivacyPolicyFailure(nonCCPrivacyPolicyError.error)
						)
						reject(nonCCPrivacyPolicyError)
					})
			}
		})
	}
}

export const GET_NON_CC_PRIVACY_POLICY_FAILURE =
	'GET_NON_CC_PRIVACY_POLICY_FAILURE'

export function getNonCCPrivacyPolicyFailure(message) {
	return {
		type: GET_NON_CC_PRIVACY_POLICY_FAILURE,
		message
	}
}

export function getAttorneyTermsOfUse() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const attorneyTerms = getState().termsAndPolicies.attorneyTerms
			if (attorneyTerms) {
				dispatch(showTermsAndPolicies(attorneyTerms, 'attorneyTerms'))
				resolve(attorneyTerms)
			} else {
				fetchAttorneyTermsOfUse()
					.then(attorneyTermsOfUseResponse => {
						if (attorneyTermsOfUseResponse.success) {
							dispatch(
								showTermsAndPolicies(
									attorneyTermsOfUseResponse.text,
									'attorneyTerms'
								)
							)
						} else {
							dispatch(
								getAttorneyTermsOfUseFailure(attorneyTermsOfUseResponse.message)
							)
						}
						resolve(attorneyTermsOfUseResponse)
					})
					.catch(attorneyTermsOfUseError => {
						dispatch(
							getAttorneyTermsOfUseFailure(attorneyTermsOfUseError.error)
						)
						reject(attorneyTermsOfUseError)
					})
			}
		})
	}
}

export const GET_ATTORNEY_TERMS_OF_USE_FAILURE =
	'GET_ATTORNEY_TERMS_OF_USE_FAILURE'

export function getAttorneyTermsOfUseFailure(message) {
	return {
		type: GET_ATTORNEY_TERMS_OF_USE_FAILURE,
		message
	}
}

export function getAttorneyPrivacyPolicy() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const attorneyPrivacySecurityAndLegal = getState().termsAndPolicies
				.attorneyPrivacySecurityAndLegal
			if (attorneyPrivacySecurityAndLegal) {
				dispatch(
					showTermsAndPolicies(
						attorneyPrivacySecurityAndLegal,
						'attorneyPrivacySecurityAndLegal'
					)
				)
				resolve(attorneyPrivacySecurityAndLegal)
			} else {
				fetchAttorneyPrivacyPolicy()
					.then(attorneyPolicyResponse => {
						if (attorneyPolicyResponse.success) {
							dispatch(
								showTermsAndPolicies(
									attorneyPolicyResponse.text,
									'attorneyPrivacySecurityAndLegal'
								)
							)
						} else {
							dispatch(getAttorneyPolicyFailure(attorneyPolicyResponse.message))
						}
						resolve(attorneyPolicyResponse)
					})
					.catch(attorneyPolicyError => {
						dispatch(getAttorneyPolicyFailure(attorneyPolicyError.error))
						reject(attorneyPolicyError)
					})
			}
		})
	}
}

export const GET_ATTORNEY_PRIVACY_POLICY_FAILURE =
	'GET_ATTORNEY_PRIVACY_POLICY_FAILURE'

export function getAttorneyPolicyFailure(message) {
	return {
		type: GET_ATTORNEY_PRIVACY_POLICY_FAILURE,
		message
	}
}

export const SHOW_TERMS_AND_POLICIES = 'SHOW_TERMS_AND_POLICIES'

export function showTermsAndPolicies(
	content,
	termType,
	showTermsFooter = false
) {
	return {
		type: SHOW_TERMS_AND_POLICIES,
		content,
		termType,
		showTermsFooter
	}
}

export const HIDE_TERMS_AND_POLICIES = 'HIDE_TERMS_AND_POLICIES'

export function hideTermsAndPolicies() {
	return {
		type: HIDE_TERMS_AND_POLICIES
	}
}

export const DECLINE_TERMS_AND_POLICIES_SUCCESS =
	'DECLINE_TERMS_AND_POLICIES_SUCCESS'

export function declineTermsAndPoliciesSuccess() {
	return {
		type: DECLINE_TERMS_AND_POLICIES_SUCCESS
	}
}

export const CANCEL_TERMS_AND_POLICIES = 'CANCEL_TERMS_AND_POLICIES'

export function cancelTermsAndPolicies() {
	return {
		type: CANCEL_TERMS_AND_POLICIES
	}
}

export function declineTermsAndPolicies() {
	return (dispatch, getState) => {
		const emailId = getState().loginWidget.inputFields.email.value
		const { showEsign } = getState().loginWidget
		if (showEsign === false) {
			dispatch(cancelTermsAndPolicies())
		} else {
			const agreementName = 'toc'
			const payload = {
				agreementName,
				emailId
			}
			return new Promise((resolve, reject) => {
				declineTerms(payload)
					.then(declineTermsResponse => {
						if (declineTermsResponse.success) {
							dispatch(declineTermsAndPoliciesSuccess())
						} else {
							dispatch(showGeneralMessage(declineTermsResponse.messageCode))
						}
						resolve(declineTermsResponse)
					})
					.catch(declineTermsError => {
						dispatch(showGeneralMessage(declineTermsError.error))
						reject(declineTermsError)
					})
			})
		}
	}
}
