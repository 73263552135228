import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import messages_en from '../locale/en.json'
import messages_fr from '../locale/fr.json'
import messages_en_GB from '../locale/GB.json'
import messages_fr_FR from '../locale/fr_FR.json'
import messages_de_DE from '../locale/de_DE.json'
import messages_au_AU from '../locale/au_AU.json'

export function mapStateToProps(state) {
	const { locale } = state.locale
	const intlConfig = {
		locale: getProviderLocale(locale),
		key: getProviderLocale(locale),
		messages: getMessages(locale)
	}
	return intlConfig
}

export function getMessages(locale) {
	if (locale === 'fr') {
		return messages_fr
	} else if (locale === 'en_GB') {
		return messages_en_GB
	} else if (locale === 'fr_FR') {
		return messages_fr_FR
	} else if (locale === 'de_DE') {
		return messages_de_DE
	} else if (locale === 'au_AU') {
		return messages_au_AU
	}
	return messages_en
}

export function getProviderLocale(locale) {
	if (locale === 'fr') {
		return 'fr'
	} else if (locale === 'en_GB') {
		return 'en-GB'
	} else if (locale === 'fr_FR') {
		return 'fr-FR'
	} else if (locale === 'de_DE') {
		return 'de-DE'
	} else if (locale === 'au_AU') {
		return 'au-AU'
	}
	return 'en'
}

export default connect(mapStateToProps)(IntlProvider)
