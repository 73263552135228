import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { WCA } from '../components/WCA'
import { getTextInputValidationMessage } from '../isValidField'

export const mapDispatchToProps = dispatch => {
	return {
		onCustomTextInputValidator(elementId, inputValue, formatMessage) {
			return getTextInputValidationMessage(elementId, inputValue, formatMessage)
		}
	}
}

export default injectIntl(
	connect(
		null,
		mapDispatchToProps
	)(WCA)
)
